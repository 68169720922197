import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { TimeZoneSelector } from 'components';
import { LocalContext } from 'context';
import { Markup } from 'interweave';
import { useWindowSize } from 'hooks';
import { hexToRGB } from 'utils';

function Agendas({ agendas, colors }) {
  const { agendaRef } = useContext(LocalContext);
  const { windowWidth } = useWindowSize();
  const [selected, setSelected] = useState(agendas[0]);
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const { slots } = selected;
  const timeZones = ['GMT', 'EST', 'CET'];

  const getHour = ({ hour, selectedTimeZone: _timeZone }) => {
    switch (_timeZone) {
      case 'CET':
        return parseInt(hour, 10) + 1;
      case 'EST':
        return parseInt(hour, 10) - 5;
      default:
        return hour;
    }
  };

  return (
    <Container ref={agendaRef}>
      <AgendaList>
        <InnerContainer>
          <GridTitle colors={colors}>{agendas.length > 1 ? 'Agendas' : 'Agenda'}</GridTitle>
          {agendas.length > 1 && (
            <DayAndTimeZoneSelectorWrapper>
              {agendas.map((agenda) => (
                <DaySelector
                  key={agenda.day}
                  colors={colors}
                  active={selected.day === agenda.day}
                  onClick={() => {
                    setSelected(agenda);
                  }}>
                  Day {agenda.day}
                </DaySelector>
              ))}
              <TimeZoneSelector
                timeZones={timeZones}
                selectedTimeZone={selectedTimeZone}
                setSelectedTimeZone={setSelectedTimeZone}
                colors={colors}
              />
            </DayAndTimeZoneSelectorWrapper>
          )}

          <Agenda colors={colors}>
            {windowWidth >= 768 && (
              <Grid className="inactive" colors={colors}>
                <Time>Time:</Time>
                <Name style={{ fontSize: '1rem', fontWeight: 'normal' }}>Livestream</Name>

                <Speaker style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                  Chair/Presenter:
                </Speaker>
              </Grid>
            )}

            {slots.map((slot) => {
              const [hour, mins] = slot.timeSlotGMT.split(':');
              const timeZoneHour = getHour({ hour, selectedTimeZone });
              const period = timeZoneHour > 11 ? 'pm' : 'am';
              const time = `${timeZoneHour > 12 ? timeZoneHour - 12 : timeZoneHour}:${mins}${period}`;
              return (
                <Grid key={slot.orderNumber} colors={colors}>
                  <Time>
                    {windowWidth < 768 && <span>Time:</span>}
                    {time}
                  </Time>
                  <Name>
                    <Markup content={slot.title} />
                  </Name>
                  <Speaker>
                    <Markup content={slot.speaker} />
                  </Speaker>
                </Grid>
              );
            })}
          </Agenda>
        </InnerContainer>
      </AgendaList>
    </Container>
  );
}

const Container = styled.section`
  background: rgba(196, 196, 196, 0.2);
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  padding: 2rem 1.25rem;
  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1.25rem;
    padding: 3rem 0;
  }
`;

const GridTitle = styled.h5`
  color: ${({ theme, colors }) =>
    theme.className === 'contrast' ? theme.secondary : colors.tertiary};
  font-size: 1.5rem;
  grid-column: 2/6;
  margin-bottom: 0.5em;

  @media only screen and (min-width: 48rem) {
    grid-column: 2/12;
  }

  @media only screen and (min-width: 1150px) {
    margin-bottom: 1.1em;
  }

  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const P = styled.p`
  color: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    margin-bottom: 1em;
  }
`;

const Name = styled(P)`
  grid-column: 1/7;
  strong,
  ul {
    font-weight: 600;
  }
  ul {
    margin-left: 2rem;
  }
  @media only screen and (min-width: 48rem) {
    grid-column: 3/7;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const Speaker = styled(P)`
  color: #3c3c3c;
  font-weight: 600;
  grid-column: 1/7;
  @media only screen and (min-width: 48rem) {
    grid-column: 7/11;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 7/11;
  }
`;

const Time = styled(P)`
  color: #3c3c3c;
  grid-column: 1/2;
  span {
    margin-right: 0.375rem;
  }
  @media only screen and (min-width: 48rem) {
    grid-column: 1/3;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/2;
  }
`;

const Grid = styled.div`
  color: ${({ className, colors }) => (className === 'inactive' ? 'initial' : colors.secondary)};
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 1.75rem;
  transition: 0.25s;
  @media only screen and (min-width: 48rem) {
    margin-bottom: 0.75rem;
    grid-template-columns: repeat(10, 1fr);
    grid-column: 2/12;
    grid-gap: 2rem;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const Title = styled.h5`
  color: ${({ colors }) => colors.secondary};
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.3em;
  margin-bottom: 2rem;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 1.5rem;
    margin-bottom: 1.325em;
  }
`;

const Agenda = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  ${Title}, ${Name} {
    strong {
      color: ${({ theme, colors }) =>
        theme.className === 'contrast' ? theme.secondary : colors.secondary};
    }
  }
`;

const DaySelector = styled.button`
  background: ${({ active, colors, theme }) =>
    active && theme.className === 'contrast'
      ? theme.primary
      : active && theme.className !== 'contrast'
        ? colors.primary
        : 'transparent'};
  border: 1px solid;
  border-color: ${({ active, colors, theme }) =>
    active && theme.className === 'contrast'
      ? theme.contrast
      : active && theme.className !== 'contrast'
        ? theme.contrast
        : !active && theme.className === 'contrast'
          ? theme.secondary
          : colors.secondary};
  border-radius: 2rem;
  color: ${({ active, colors, theme }) =>
    active && theme.className === 'contrast'
      ? theme.contrast
      : active && theme.className !== 'contrast'
        ? theme.contrast
        : !active && theme.className === 'contrast'
          ? theme.secondary
          : colors.secondary};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  text-align: center;
  transition: 0.25s;
`;

const DayAndTimeZoneSelectorWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  gap: 1.25rem;
  margin-bottom: 1.5rem;
  ${DaySelector} {
    width: 7.5rem;
  }

  > {
    margin: 0 !important;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1/7;
  height: auto;
  justify-content: flex-start;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
  }
`;

const AgendaList = styled.div`
  display: grid;
  grid-column: 1/13;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 3rem auto;
  max-width: 1440px;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1150px) {
    grid-column-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
    padding: 1rem;
    margin: 0 auto;
  }
`;

export default Agendas;
