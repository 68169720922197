/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { AgencyxLogoContrast, DaiichiAstrazenecaLogos } from 'assets/svgs';

function EventFooter({ background, jobCode, dateOfPreperation }) {
  return (
    <Footer>
      <Content>
        <a href="https://www.daiichi-sankyo.ie/" target="blank" rel="noreferrer">
          <DaiichiAstrazenecaLogos />
        </a>
        <P>
          This is a non-promotional, educational webinar organized and funded by Daiichi Sankyo
          Ireland on behalf of the Daiichi Sankyo/AstraZeneca Alliance
          <br />
          <br />
          Produced by
        </P>
        <a href="https://agencyx.ie" target="blank" rel="noreferrer">
          <AgencyxLogoContrast className="ax-logo" width={180} />
        </a>
        <P>
          <small>
            Address: 12 Hatch Street Lower, Saint Kevin&apos;s, Dublin 2, D02 R682
            <br />
            Email: hello@agencyx.ie, Phone: (01) 670 6122
          </small>
        </P>
      </Content>
      <Background>
        <GatsbyImage image={getImage(background)} alt="Lungs" />
      </Background>
      <FooterInfo>
        <div>
          <FooterCopyright>Copyright © 2024 - Designed and Developed by Agency X </FooterCopyright>
          <FooterJobCode>
            {jobCode} · Date of Preparation: {dateOfPreperation}
          </FooterJobCode>
          <FooterTerms>
            <a href="https://www.daiichi-sankyo.ie/disclaimer/" target="_blank" rel="noreferrer">
              Disclaimer
            </a>
            <a
              href="https://www.daiichi-sankyo.ie/data-protection/"
              target="_blank"
              rel="noreferrer">
              Data Protection
            </a>
            <a href="https://www.daiichi-sankyo.ie/imprint/" target="_blank" rel="noreferrer">
              Imprint
            </a>
          </FooterTerms>
        </div>
      </FooterInfo>
    </Footer>
  );
}

const Footer = styled.footer`
  background: ${({ theme }) => (theme.className === 'contrast' ? theme.contrast : theme.primary)};
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-row-gap: 3.75rem;
  grid-template-columns: repeat(6, 1fr);
  overflow: hidden;
  padding: 2.25rem 1.25rem 8rem 1.25rem;
  position: relative;
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media only screen and (min-width: 48rem) {
    padding: 2.25rem 1.25rem 8rem 1.25rem;
  }
  @media only screen and (min-width: 1150px) {
    padding: 2.25rem 0 5rem;
  }
  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const P = styled.p`
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  grid-column: 1/7;
  max-width: 560px;
  position: relative;
  z-index: 1;
  @media only screen and (min-width: 1150px) {
    grid-column: 2/7;
  }
`;

const FooterInfo = styled.footer`
  align-items: start;
  background: rgba(255, 255, 255, 0.85);
  bottom: 0;
  display: grid;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  left: 0;
  padding: 1rem 0;
  position: absolute;
  width: 100%;
  z-index: 2;
  > div {
    display: flex;
    flex-direction: column;
    grid-column: 1/7;
    padding: 0 1.25rem;
  }
  a {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-position: under;
  }
  @media only screen and (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
    height: 48px;
    > div {
      flex-direction: row;
      justify-content: space-between;
      grid-column: 2/12;
      padding: 0;
    }
  }
`;

const FooterCopyright = styled.p`
  color: ${({ theme }) => theme.primary};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 1.25rem;
  margin-top: 0px;
`;

const FooterJobCode = styled.p`
  color: ${({ theme }) => theme.primary};
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  grid-column: 1/7;
  line-height: 1.25rem;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    text-align: center;
  }
`;

const FooterTerms = styled.div`
  display: flex;
  grid-column: 1/7;
  justify-content: flex-start;
  a {
    color: ${({ theme }) => theme.primary};
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-right: 1.25rem;
  }
  @media only screen and (min-width: 1150px) {
    justify-content: flex-end;
  }
`;

export default EventFooter;
