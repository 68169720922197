import styled from 'styled-components';
import { hexToRGB } from 'utils';

export const defaultColors = {
  primary: '#3C3C3C',
  secondary: '#1752A0',
  tertiary: '#FFA74A',
  quaternary: '#ff9559',
  warning: '#f55151'
};

export const CardDeck = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-row-gap: 2.5rem;
  grid-template-columns: 1fr;
  padding: 0 1.25rem;
  position: relative;
  width: 100%;
  @media only screen and (min-width: 48rem) {
    grid-column-gap: ${({ large }) => (large ? '3rem' : '2rem')};
    grid-row-gap: 4rem;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    justify-content: space-between;
  }
  @media only screen and (min-width: 1150px) {
    grid-column-gap: ${({ large }) => (large ? '3rem' : '2rem')};
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: space-between;
  }
`;

export const fadeInAndOutVariants = ({
  animateDelay = 0,
  animateDuration = 0.2,
  exitDelay = 0,
  exitDuration = 0.2
} = {}) => ({
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: animateDelay,
      duration: animateDuration
    }
  },
  exit: {
    opacity: 0,
    transition: {
      delay: exitDelay,
      duration: exitDuration
    }
  }
});

export const fadeInAndOutAndAnimateHeightVariants = () => ({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      opacity: {
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.2,
      height: {
        delay: 0.1
      }
    }
  }
});

export const customDropdownMenuStyles = ({ colors, theme, style }) => ({
  container: (provided, state) => ({
    ...provided,
    alignItems: 'center',
    background: 'transparent',
    border: state.isFocused
      ? `0.188em solid ${theme.className === 'contrast' ? theme.primary : theme.contrast}`
      : `0.063em solid ${theme.className === 'contrast' ? theme.primary : theme.contrast}`,
    display: 'flex',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '1rem',
    fontWeight: 'bold',
    height: '3.75rem',
    letterSpacing: '0.031rem',
    padding: '0',
    ...style
  }),
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    padding: '0 0 0 1.25rem',
    cursor: style?.cursor ? style.cursor : 'pointer',
    height: '100%'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: theme.className === 'contrast' ? theme.primary : theme.contrast,
    '&:hover': {
      color: theme.className === 'contrast' ? theme.primary : theme.contrast
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  input: (provided) => ({
    ...provided,
    color: theme.className === 'contrast' ? theme.primary : theme.contrast
  }),
  menu: (provided) => ({
    ...provided,
    background: theme.className === 'contrast' ? theme.contrast : colors.primary,
    border: `0.214em solid ${theme.className === 'contrast' ? theme.primary : theme.contrast}`,
    borderRadius: '0',
    fontFamily: "'Noto Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 'bold',
    left: '-0.214em',
    letterSpacing: '0.071em',
    margin: '0',
    width: 'calc(100% + 0.429em)'
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: theme.className === 'contrast' ? theme.primary : theme.contrast
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? `${
          theme.className === 'contrast'
            ? hexToRGB({ color: theme.primary, alpha: 0.3 })
            : colors.tertiary
        }`
      : 'transparent',
    paddingLeft: '1.25rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor:
        theme.className === 'contrast'
          ? hexToRGB({ color: theme.primary, alpha: 0.3 })
          : colors.secondary
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'Noto Sans', sans-serif",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: theme.className === 'contrast' ? theme.primary : theme.contrast,
    opacity: 0.65
  }),
  singleValue: (provided) => ({
    ...provided,
    color: theme.className === 'contrast' ? theme.primary : theme.contrast
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0'
  })
});

const styles = {
  customDropdownMenuStyles
};

export default styles;
