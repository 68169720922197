import React, { useContext } from 'react';
import styled from 'styled-components';
import { LoginOrSignUp, DateButton } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { DaiichiAstrazenecaLogos } from 'assets/svgs';
import { LocalContext, FirebaseContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { Markup } from 'interweave';
import { fadeInAndOutVariants } from 'styles';

function HubHeader({ event }) {
  const { isMobile, registerRef, showLoginOrSignUpDialog, showSignUpForm } =
    useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const currentUser = firebase?.auth?.currentUser;

  return (
    <Container colors={event.colors}>
      <EventBanner>
        {event.bannerVideo ? (
          <EventBannerVideo autoPlay muted id="bgvid" controlsList="nofullscreen">
            <source src={event.bannerVideo} type="video/mp4" />
          </EventBannerVideo>
        ) : (
          <GatsbyImage image={getImage(event.banner)} alt={event.name} />
        )}
      </EventBanner>
      <AnimatePresence>
        <Main
          variants={fadeInAndOutVariants()}
          showLoginOrSignUpDialog={showLoginOrSignUpDialog}
          showSignUpForm={showSignUpForm}
          ref={registerRef}
          id="login-or-signup">
          <EventDetails>
            <EventDate>
              <Markup content={event.date} />
              <br />
              LIVE WEBINAR
            </EventDate>
            <EventTitle>
              <Markup content={event.name} />
            </EventTitle>
            <P>
              <Markup content={event.description} />
            </P>
            <LogoContainer>
              <DaiichiAstrazenecaLogos />
            </LogoContainer>
            <DateButton
              eventName={event.name}
              eventDescription={event.description}
              eventStartTime={event.startTime}
              eventEndTime={event.endTime}
              eventLocation={event.location}
              colors={event.colors}
              style={{ marginLeft: '-1.25rem' }}
            />
          </EventDetails>
          {!currentUser && (
            <AnimatePresence>
              {!isMobile && !currentUser && !showLoginOrSignUpDialog && (
                <RegFormGrid
                  id="login-or-signup"
                  showLoginOrSignUpDialog={showLoginOrSignUpDialog}
                  showSignUpForm={showSignUpForm}>
                  <LoginOrSignUp colors={event.colors} hideCloseIcon shine />
                </RegFormGrid>
              )}
            </AnimatePresence>
          )}
        </Main>
      </AnimatePresence>
    </Container>
  );
}

const LogoContainer = styled.div`
  height: auto;
  margin-bottom: 1rem;
  max-width: 100%;
  @media only screen and (min-width: 48rem) {
    max-width: 450px;
  }
`;

const EventTitle = styled.h2`
  color: inherit;
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 1.4;
  margin: 0.5rem 0;
  @media only screen and (min-width: 1150px) {
    font-size: 3.375rem;
    margin: 1rem 0;
  }
`;

const P = styled.p`
  color: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 1rem;
  @media only screen and (min-width: 1150px) {
    font-size: 1.25rem;
  }
`;

const EventDate = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
`;

const RegFormGrid = styled(motion.div).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
})`
  grid-row: 1/2;
  grid-column: 7/11;
  max-width: 26.5rem;
  position: relative;
  left: 0.625rem;

  /* Form */
  > :last-child {
    margin: 0 auto;
    transform: ${({ showLoginOrSignUpDialog, showSignUpForm }) =>
      !showLoginOrSignUpDialog && showSignUpForm
        ? 'translate3d(0, 18.15rem, 0)'
        : 'translate3d(0, 10.15rem, 0)'};
    transition: transform 0.5s ease-in-out;
    transform-style: preserve-3d;
  }
`;

const Main = styled(motion.section)`
  display: grid;
  grid-column: 1/7;
  grid-gap: 0.625rem;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 2rem;
  padding: 3rem 1.25rem;
  transition: margin-bottom 0.4s ease-in-out;

  @media only screen and (min-width: 48rem) {
    grid-column: 2/6;
    padding: 3rem 0 0;
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 2/12;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1.25rem;
    margin-bottom: ${({ showLoginOrSignUpDialog, showSignUpForm }) =>
      !showLoginOrSignUpDialog && showSignUpForm ? '8rem' : 0};
    padding: 5rem 0 2rem 0;
  }
  @media only screen and (min-width: 1441px) {
    grid-column: 3/11;
  }
`;

const EventDetails = styled.div`
  grid-column: 1/7;
  position: relative;
  @media only screen and (min-width: 1150px) {
    grid-column: 1/6;
  }
`;

const EventBannerVideo = styled.video`
  height: 100%;
  left: -20%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: auto;
  z-index: 1;
  @media only screen and (min-width: 48rem) {
    height: 100%;
    left: 45%;
    object-fit: cover;
    object-position: center;
  }
`;

const EventBanner = styled.div`
  height: 100%;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const Container = styled.header`
  align-items: center;
  background: #000000;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#FFFFFF')};
  display: grid;
  grid-column: 1/7;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  justify-content: center;
  min-height: 100vh;
  overflow: visible;
  padding: 0.625rem;
  padding-top: 5rem;
  position: relative;
  width: 100%;

  @media only screen and (min-width: 48rem) and (min-height: 1000px) and (max-width: 1150px) {
    min-height: 70vh;
    padding: 4rem 0;
  }

  @media only screen and (min-width: 1150px) {
    grid-column: 1/13;
    grid-template-columns: repeat(12, 1fr);
    height: auto;
  }
`;

export default HubHeader;
