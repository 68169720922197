import React from 'react';
import styled from 'styled-components';
import { FormContainer } from '../FormComponents';

function LoggingExistingUserIn() {
  return (
    <CustomContainer>
      <H3>Logging In</H3>
    </CustomContainer>
  );
}

const H3 = styled.h3`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: bold;
  justify-content: center;
  line-height: 1.75rem;
  text-align: center;
  &:after {
    animation: dots 1s steps(5, end) infinite;
    content: ' .';
    margin-left: 0.25rem;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        0.5rem 0 0 rgba(0, 0, 0, 0),
        1rem 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: #fff;
      text-shadow:
        0.5rem 0 0 rgba(0, 0, 0, 0),
        1rem 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow:
        0.5rem 0 0 #fff,
        1rem 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow:
        0.5rem 0 0 #fff,
        1rem 0 0 #fff;
    }
  }
`;

const CustomContainer = styled(FormContainer)`
  margin: 20px auto;
  max-width: 450px;

  @media screen and (min-width: 1150px) {
    padding: 40px 20px;
  }
`;

export default LoggingExistingUserIn;
