import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  CreateOrEditPollModal,
  QueuedAndClosedPolls,
  GeneralAnalyticsModal,
  FeedbackAnalyticsModal,
  WordCloudAnalyticsModal,
  ConfirmDelete,
  Dialog
} from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { Plus } from 'assets/svgs';

function ControlPanel({ colors, eventId }) {
  const {
    handleDialog,
    confirmDeletePollOrQuestionDialogRef,
    showConfirmDeleteDialog,
    setShowConfirmDeleteDialog,
    browserTabIsActive
  } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);
  const [pollBeingCurrentlyEditedByModerator, setPollBeingCurrentlyEditedByModerator] =
    useState(null);
  const [isCreateOrEditPollModalOpen, setIsCreateOrEditPollModalOpen] = useState(false);
  const [queuedPolls, setPendingPolls] = useState([]);
  const [closedPolls, setClosedPolls] = useState([]);
  const [allPolls, setAllPolls] = useState([]);
  const [selectedPoll, setSelectedPoll] = useState(null);
  const [currentlyOpenPoll, setCurrentlyOpenPoll] = useState(null);
  const [pollToBeDeleted, setPollToBeDeleted] = useState(null);
  const [isAnalyticsModalOpen, setIsAnalyticsModalOpen] = useState(false);
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const [newPollType, setNewPollType] = useState(null);
  const [
    pollAnalyticsBeingCurrentlyViewedByModerator,
    setPollAnalyticsBeingCurrentlyViewedByModerator
  ] = useState(null);
  const [
    pollAnalyticsBeingCurrentlySharedWithUsers,
    setPollAnalyticsBeingCurrentlySharedWithUsers
  ] = useState(null);

  useEffect(() => {
    let unsubscribeFromPolls;

    if (firebase && browserTabIsActive) {
      unsubscribeFromPolls = firebase.interaction.polls.subscribeToPolls({
        eventId,
        snapshot: (snapshot) => {
          if (snapshot.exists()) {
            const _queuedPolls = [];
            const _closedPolls = [];
            const _allPolls = [];

            snapshot.forEach((child) => {
              _allPolls.push({
                pollId: child.key,
                ...child.val()
              });
            });

            const _currentlyOpenPoll = _allPolls.find((poll) => poll.isOpen);

            const _pollAnalyticsBeingCurrentlySharedWithUsers = _allPolls.find(
              (poll) => poll.shareResults
            );

            if (_currentlyOpenPoll) {
              setCurrentlyOpenPoll(_currentlyOpenPoll);
            } else {
              setCurrentlyOpenPoll(null);
            }

            if (_pollAnalyticsBeingCurrentlySharedWithUsers) {
              setPollAnalyticsBeingCurrentlySharedWithUsers(
                _pollAnalyticsBeingCurrentlySharedWithUsers
              );
            } else {
              setPollAnalyticsBeingCurrentlySharedWithUsers(null);
            }

            _allPolls.forEach((poll) => {
              if (poll.isQueued) {
                _queuedPolls.push(poll);
              } else {
                _closedPolls.push(poll);
              }
            });

            setPendingPolls(_queuedPolls);
            setClosedPolls(_closedPolls);
            setAllPolls(_allPolls);
          } else if (!snapshot.exists()) {
            setPendingPolls([]);
            setClosedPolls([]);
            setAllPolls([]);
            setCurrentlyOpenPoll(null);
            setPollAnalyticsBeingCurrentlySharedWithUsers(null);
          }
        }
      });
    }
    return () => {
      if (unsubscribeFromPolls) {
        unsubscribeFromPolls();
      }
    };
  }, [firebase, browserTabIsActive]);

  useEffect(() => {
    if (allPolls.length) {
      allPolls.forEach((poll) => {
        if (selectedPoll && selectedPoll.pollId === poll.pollId) {
          // Whenever the 'subscribeToPolls' snapshot is fired we loop through all the polls and
          // rehydrate the currently selected poll (if there is one) with any new properties
          // e.g. if it's been changed from a queued to a closed poll.
          setSelectedPoll(poll);
        }
      });
    }
  }, [allPolls]);

  const handlePoll = async (action) => {
    switch (action) {
      case 'openPoll':
        if (currentlyOpenPoll && currentlyOpenPoll.pollId !== selectedPoll.pollId) {
          await firebase.interaction.polls.closePoll({ eventId, poll: currentlyOpenPoll });
        }

        if (pollAnalyticsBeingCurrentlySharedWithUsers) {
          await firebase.interaction.polls.stopSharingPollAnalytics({
            eventId,
            poll: pollAnalyticsBeingCurrentlySharedWithUsers
          });
        }

        firebase.interaction.polls.openPoll({ eventId, selectedPoll, currentlyOpenPoll });
        firebase.interaction.forceActiveTab({ eventId, tabName: 'Polls' });
        break;
      case 'closePoll':
        firebase.interaction.polls.closePoll({ eventId, poll: selectedPoll });
        firebase.interaction.forceActiveTab({ eventId, tabName: null });
        setCurrentlyOpenPoll(null);
        break;
      case 'sharePollAnalytics':
        if (currentlyOpenPoll) {
          await firebase.interaction.polls.closePoll({ eventId, poll: currentlyOpenPoll });
        }

        if (
          pollAnalyticsBeingCurrentlySharedWithUsers &&
          pollAnalyticsBeingCurrentlySharedWithUsers.pollId !== selectedPoll.pollId
        ) {
          await firebase.interaction.polls.stopSharingPollAnalytics({
            eventId,
            poll: pollAnalyticsBeingCurrentlySharedWithUsers
          });
        }

        firebase.interaction.polls.sharePollAnalytics({ eventId, poll: selectedPoll });
        firebase.interaction.forceActiveTab({ eventId, tabName: 'Polls' });
        break;
      case 'stopSharingPollAnalytics':
        firebase.interaction.polls.stopSharingPollAnalytics({ eventId, poll: selectedPoll });
        firebase.interaction.forceActiveTab({ eventId, tabName: null });
        break;
      default:
        break;
    }
  };

  const handleDeletePoll = (e) => {
    e.stopPropagation();
    if (firebase) {
      if (pollToBeDeleted?.pollId === selectedPoll?.pollId) {
        setSelectedPoll(null);
      }
      if (pollToBeDeleted?.pollId === currentlyOpenPoll?.pollId) {
        firebase.interaction.forceActiveTab({ eventId, tabName: null });
        setCurrentlyOpenPoll(null);
      }
      firebase.interaction.polls.deletePoll({ eventId, poll: pollToBeDeleted }).then(() =>
        handleDialog({
          dialogRef: confirmDeletePollOrQuestionDialogRef,
          animation: 'close',
          stateHandler: () => setShowConfirmDeleteDialog(false),
          callback: () => setPollToBeDeleted(null)
        })
      );
    }
  };

  const handleDismissDeletePoll = (e) => {
    e.stopPropagation();
    handleDialog({
      dialogRef: confirmDeletePollOrQuestionDialogRef,
      animation: 'dismiss',
      stateHandler: () => setShowConfirmDeleteDialog(false),
      callback: () => setPollToBeDeleted(null)
    });
  };

  return (
    <>
      <p>Moderator&apos;s Control Panel</p>
      <motion.div
        style={{
          alignSelf: 'center',
          height: '2.75rem'
        }}
        onClick={() => setIsDropdownMenuOpen(true)}
        onMouseLeave={() => setIsDropdownMenuOpen(false)}
        onTap={() => isMobile && setIsDropdownMenuOpen((currentValue) => !currentValue)}>
        <CreatePoll
          animate={{ backgroundColor: isDropdownMenuOpen ? colors.tertiary : colors.tertiary }}
          transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}>
          <Plus width="0.625rem" />
          &nbsp;&nbsp;Create Poll
          <AnimatePresence>
            {isDropdownMenuOpen && (
              <PollDropdown
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  collapsed: { opacity: 0, rotateX: -90 },
                  open: { opacity: 1, rotateX: 0 }
                }}
                transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}>
                <ul>
                  <li>
                    <motion.button
                      whileHover={{
                        color: colors.tertiary
                      }}
                      whileTap={{
                        scale: 0.95
                      }}
                      type="button"
                      onClick={() => {
                        setNewPollType('general poll');
                        setIsCreateOrEditPollModalOpen(true);
                      }}>
                      General Poll
                    </motion.button>
                  </li>
                  <li>
                    <motion.button
                      whileHover={{
                        color: colors.tertiary
                      }}
                      whileTap={{
                        scale: 0.95
                      }}
                      type="button"
                      onClick={() => {
                        setNewPollType('rating poll');
                        setIsCreateOrEditPollModalOpen(true);
                      }}>
                      Rating Feedback Poll
                    </motion.button>
                  </li>
                  <li>
                    <motion.button
                      whileHover={{
                        color: colors.tertiary
                      }}
                      whileTap={{
                        scale: 0.95
                      }}
                      type="button"
                      onClick={() => {
                        setNewPollType('open feedback poll');
                        setIsCreateOrEditPollModalOpen(true);
                      }}>
                      Open Feedback
                    </motion.button>
                  </li>
                  {/* <li>
                    <motion.button
                      whileHover={{
                        color: colors.tertiary
                      }}
                      whileTap={{
                        scale: 0.95
                      }}
                      type="button"
                      onClick={() => {
                        setNewPollType('word cloud');
                        setIsCreateOrEditPollModalOpen(true);
                      }}>
                      Word Cloud
                    </motion.button>
                  </li> */}
                </ul>
              </PollDropdown>
            )}
          </AnimatePresence>
        </CreatePoll>
      </motion.div>
      <QueuedAndClosedPolls
        eventId={eventId}
        colors={colors}
        queuedPolls={queuedPolls}
        closedPolls={closedPolls}
        selectedPoll={selectedPoll}
        setSelectedPoll={setSelectedPoll}
        currentlyOpenPoll={currentlyOpenPoll}
        setCurrentlyOpenPoll={setCurrentlyOpenPoll}
        setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
        setPollBeingCurrentlyEditedByModerator={setPollBeingCurrentlyEditedByModerator}
        setPollAnalyticsBeingCurrentlyViewedByModerator={
          setPollAnalyticsBeingCurrentlyViewedByModerator
        }
        setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
        setPollToBeDeleted={setPollToBeDeleted}
        setShowConfirmDeleteDialog={setShowConfirmDeleteDialog}
      />
      <AnimatePresence>
        {(queuedPolls.length || closedPolls.length) && (
          <Buttons colors={colors}>
            <AnimatePresence>
              {queuedPolls.length && (!selectedPoll || (selectedPoll && selectedPoll.isQueued)) && (
                <Button
                  onClick={() =>
                    selectedPoll?.pollId === currentlyOpenPoll?.pollId
                      ? handlePoll('closePoll')
                      : handlePoll('openPoll')
                  }
                  disabled={!selectedPoll}
                  color={
                    selectedPoll && selectedPoll.pollId === currentlyOpenPoll?.pollId
                      ? 'tertiary'
                      : 'tertiary'
                  }>
                  {selectedPoll && selectedPoll.pollId === currentlyOpenPoll?.pollId
                    ? 'Close Poll'
                    : 'Open Poll'}
                </Button>
              )}
            </AnimatePresence>
            <Button
              onClick={() =>
                selectedPoll &&
                selectedPoll.pollId === pollAnalyticsBeingCurrentlySharedWithUsers?.pollId
                  ? handlePoll('stopSharingPollAnalytics')
                  : handlePoll('sharePollAnalytics')
              }
              disabled={
                !selectedPoll || selectedPoll.isQueued || selectedPoll.type === 'open feedback poll'
              }
              color={
                selectedPoll &&
                selectedPoll.pollId === pollAnalyticsBeingCurrentlySharedWithUsers?.pollId
                  ? 'tertiary'
                  : 'secondary'
              }
              style={{ gridColumn: 2 }}>
              {selectedPoll?.shareResults ? 'Stop Sharing' : 'Share Results'}
            </Button>
          </Buttons>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isCreateOrEditPollModalOpen && (
          <CreateOrEditPollModal
            colors={colors}
            eventId={eventId}
            setIsCreateOrEditPollModalOpen={setIsCreateOrEditPollModalOpen}
            pollBeingCurrentlyEditedByModerator={pollBeingCurrentlyEditedByModerator}
            setPollBeingCurrentlyEditedByModerator={setPollBeingCurrentlyEditedByModerator}
            newPollType={newPollType}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlyViewedByModerator?.type === 'general poll' && (
            <GeneralAnalyticsModal
              eventId={eventId}
              colors={colors}
              poll={pollAnalyticsBeingCurrentlyViewedByModerator}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlyViewedByModerator?.type === 'rating poll' && (
            <FeedbackAnalyticsModal
              eventId={eventId}
              colors={colors}
              poll={pollAnalyticsBeingCurrentlyViewedByModerator}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
        {isAnalyticsModalOpen &&
          pollAnalyticsBeingCurrentlyViewedByModerator?.type === 'word cloud' && (
            <WordCloudAnalyticsModal
              eventId={eventId}
              colors={colors}
              poll={pollAnalyticsBeingCurrentlyViewedByModerator}
              setIsAnalyticsModalOpen={setIsAnalyticsModalOpen}
            />
          )}
      </AnimatePresence>
      <AnimatePresence>
        <Dialog
          ref={confirmDeletePollOrQuestionDialogRef}
          isVisible={showConfirmDeleteDialog}
          onDismiss={handleDismissDeletePoll}
          contentStyle={{
            maxWidth: '23.75rem'
          }}>
          <ConfirmDelete
            colors={colors}
            handleDelete={handleDeletePoll}
            handleDismiss={handleDismissDeletePoll}
            type="poll"
          />
        </Dialog>
      </AnimatePresence>
    </>
  );
}

const CreatePoll = styled(motion.div)`
  align-items: center;
  border-radius: 0.125em;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  height: 2.5em;
  justify-content: center;
  margin: 0 auto;
  padding: 1em;
  position: relative;
  text-transform: uppercase;
  width: 11.875em;
`;

const PollDropdown = styled(motion.div)`
  bottom: -7.75rem;
  position: absolute;
  transform-origin: top center;
  width: 100%;
  z-index: 1;
  ul {
    align-items: center;
    background-color: #fff;
    border-radius: 0.125rem;
    box-shadow: 0.063rem 0.063rem 0.75rem 0.125rem rgba(196, 196, 196, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 10.75rem;

    li {
      font-size: 0.75rem;
      line-height: 2em;
      list-style: none;
      padding: 0.667em;
      text-align: center;
      text-decoration: none;

      button {
        background: transparent;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

const Buttons = styled.section`
  align-items: center;
  border-top: 0.063rem solid #bdbdbd;
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(2, minmax(auto, 11.875rem));
  height: 4.05rem;
  justify-content: space-between;
  padding: 0.825rem 0 0.325rem;

  button {
    border-radius: 0.125rem;
    height: 2.5rem;
    max-width: 11.875rem;
    white-space: nowrap;
  }
`;

export default ControlPanel;
