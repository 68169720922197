import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Timer, Button } from 'components';
import { FirebaseContext } from 'context';
import { hexToRGB, capitaliseFirstLetterOfText } from 'utils';
import { fadeInAndOutVariants } from 'styles';

function GeneralAnswerPollModal({ poll, serverTimeOffset, eventId, colors }) {
  const { firebase, user } = useContext(FirebaseContext);
  const [pollStatus, setPollStatus] = useState(null);
  const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);
  const [pollTimerHasExpired, setPollTimerHasExpired] = useState(false);
  const [userHasAlreadyAnsweredThisPoll, setUserHasAlreadyAnsweredThisPoll] = useState(null);
  const [pollHasCorrectAnswers, setPollHasCorrectAnswers] = useState(null);
  const [answerButtonIsDisabled, setAnswerButtonIsDisabled] = useState(null);
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(null);

  useEffect(() => {
    if (firebase && user && poll) {
      firebase.interaction.polls
        .checkIfUserHasAlreadyAnsweredThisPoll({
          uid: user.uid,
          eventId,
          pollId: poll.pollId
        })
        .then((doc) => {
          if (doc.data()) {
            setUserHasAlreadyAnsweredThisPoll(true);
            const { answers } = doc.data();
            setSelectedAnswerIds(answers);
          } else {
            setUserHasAlreadyAnsweredThisPoll(false);
            setPollStatus('');
            setSelectedAnswerIds([]);
          }
        });
    }
  }, [firebase, user, poll, pollStatus, eventId]);

  useEffect(
    () => setPollHasCorrectAnswers(poll.answers.some((answer) => answer.isCorrect)),
    [poll]
  );

  useEffect(() => {
    setAnswerButtonIsDisabled(
      userHasAlreadyAnsweredThisPoll ||
        (poll.timer.enabled && pollTimerHasExpired) ||
        pollStatus === 'submitted'
    );
  }, [userHasAlreadyAnsweredThisPoll, poll, pollTimerHasExpired, pollStatus]);

  useEffect(() => {
    setSubmitButtonIsDisabled(
      userHasAlreadyAnsweredThisPoll ||
        !selectedAnswerIds.length ||
        (poll.timer.enabled && pollTimerHasExpired) ||
        pollStatus === 'submitted'
    );
  }, [userHasAlreadyAnsweredThisPoll, selectedAnswerIds, poll, pollTimerHasExpired, pollStatus]);

  const handleAnswerSelection = (e) => {
    const answerId = e.target.id;

    /* TODO: can I remove the optional chaining here? */
    if (poll?.multipleAnswers.allowed) {
      if (selectedAnswerIds.includes(answerId)) {
        setSelectedAnswerIds((currentlySelectedAnswerIds) =>
          currentlySelectedAnswerIds.filter(
            (currentlySelectedAnswerId) => currentlySelectedAnswerId !== answerId
          )
        );
      } else if (selectedAnswerIds.length < poll.multipleAnswers.max) {
        setSelectedAnswerIds((currentlySelectedAnswerIds) => [
          ...currentlySelectedAnswerIds,
          answerId
        ]);
      }
    } else if (selectedAnswerIds.includes(answerId)) {
      setSelectedAnswerIds([]);
    } else {
      setSelectedAnswerIds([e.target.id]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (pollStatus !== 'submitted') {
      try {
        setPollStatus('submitting');
        await firebase.interaction.polls.submitPollAnswer({
          eventId,
          poll,
          uid: user.uid,
          selectedAnswerIds
        });

        setPollStatus('submitted');
      } catch (error) {
        console.error(error);
        setPollStatus('error');
      }
    }
  };

  return (
    <Underlay colors={colors} data-underlay>
      <Modal colors={colors} pollTimerEnabled={poll.timer.enabled}>
        {poll.timer.enabled && serverTimeOffset && (
          <Timer
            colors={colors}
            serverTimeOffset={serverTimeOffset}
            setPollTimerHasExpired={setPollTimerHasExpired}
            timerEndsAt={poll.timer.startedAt + poll.timer.seconds * 1000}
            poll={poll}
          />
        )}
        <Question colors={colors}>
          <p>{capitaliseFirstLetterOfText(poll.question.text)}</p>
          {poll.multipleAnswers?.allowed && <p>(Select all that apply)</p>}
        </Question>
        <form onSubmit={handleSubmit}>
          {poll.answers.map((answer) => (
            <Answer
              colors={colors}
              disabled={userHasAlreadyAnsweredThisPoll || answerButtonIsDisabled}
              id={answer.id}
              isCorrect={answer.isCorrect}
              isSelected={selectedAnswerIds.includes(answer.id)}
              key={answer.id}
              maxAnswersSelected={selectedAnswerIds.length === poll.multipleAnswers.max}
              onClick={handleAnswerSelection}
              pollHasCorrectAnswers={pollHasCorrectAnswers}
              pollStatus={pollStatus}
              showBorder={pollHasCorrectAnswers && selectedAnswerIds.includes(answer.id)}
              type="button"
              userHasAlreadyAnsweredThisPoll={userHasAlreadyAnsweredThisPoll}
              whileTap={
                !answerButtonIsDisabled &&
                (selectedAnswerIds.includes(answer.id) ||
                  selectedAnswerIds.length + 1 <= poll.multipleAnswers.max) && {
                  scale: 0.95
                }
              }>
              <Checkbox
                colors={colors}
                isCorrect={answer.isCorrect}
                isSelected={selectedAnswerIds.includes(answer.id)}
                pollHasCorrectAnswers={pollHasCorrectAnswers}
                pollStatus={pollStatus}
                userHasAlreadyAnsweredThisPoll={userHasAlreadyAnsweredThisPoll}>
                <div />
              </Checkbox>
              <p>{capitaliseFirstLetterOfText(answer.text)}</p>
            </Answer>
          ))}
          <Button
            disabled={submitButtonIsDisabled}
            type="submit"
            loading={pollStatus === 'submitting'}
            loadingButton
            style={{
              margin: '1rem auto 0'
            }}>
            {pollStatus === 'submitted' || userHasAlreadyAnsweredThisPoll ? 'Submitted' : 'Submit'}
          </Button>
        </form>
      </Modal>
    </Underlay>
  );
}

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: ${({ pollTimerEnabled }) => (pollTimerEnabled ? '6rem auto 0' : '3rem auto 0')};
  max-width: 23.75rem;
  position: relative;

  form {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.875rem;
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.tertiary, alpha: 0.2 })};
  color: ${({ colors }) => colors.tertiary};
  padding: 0.75rem 1.5rem;
  width: 100%;
  p:nth-of-type(1) {
    font-weight: 700;
  }
  p:nth-of-type(2) {
    font-weight: 300;
  }
`;

const Answer = styled(motion.button)`
  align-items: center;
  background-color: ${({
    isSelected,
    colors,
    pollStatus,
    pollHasCorrectAnswers,
    isCorrect,
    userHasAlreadyAnsweredThisPoll
  }) =>
    pollHasCorrectAnswers &&
    ((pollStatus === 'submitted' && isSelected && isCorrect) ||
      (userHasAlreadyAnsweredThisPoll && isCorrect))
      ? 'rgba(51, 204, 102, 0.3)'
      : pollHasCorrectAnswers &&
          ((pollStatus === 'submitted' && isSelected && !isCorrect) ||
            (userHasAlreadyAnsweredThisPoll && !isCorrect))
        ? 'rgba(245, 81, 81, 0.4)'
        : isSelected
          ? hexToRGB({ color: colors.tertiary, alpha: 0.2 })
          : 'rgba(196, 196, 196, 0.2)'};
  border-radius: 0.125em;
  color: ${({
    isSelected,
    colors,
    pollStatus,
    pollHasCorrectAnswers,
    isCorrect,
    userHasAlreadyAnsweredThisPoll
  }) =>
    pollHasCorrectAnswers &&
    ((pollStatus === 'submitted' && isSelected && isCorrect) ||
      (userHasAlreadyAnsweredThisPoll && isCorrect))
      ? '#33cc66'
      : pollHasCorrectAnswers &&
          ((pollStatus === 'submitted' && isSelected && !isCorrect) ||
            (userHasAlreadyAnsweredThisPoll && !isCorrect))
        ? '#f55151'
        : isSelected
          ? colors.tertiary
          : '#000'};
  cursor: ${({ disabled, maxAnswersSelected, isSelected }) =>
    disabled || (maxAnswersSelected && !isSelected) ? 'default' : 'pointer'};
  display: flex;
  font-size: 1rem;
  margin-bottom: 0.375em;
  outline: ${({ colors, showBorder }) => (showBorder ? `2px solid ${colors.tertiary}` : 'none')};
  padding: 0.5em;
  transition:
    background-color 150ms ease-in-out,
    color 150ms ease-in-out;
  div,
  p {
    pointer-events: none;
  }
  p {
    position: relative;
    text-align: left;
    top: 0.063em;
  }
`;

const Checkbox = styled.div`
  align-items: center;
  border-color: ${({
    isSelected,
    colors,
    pollStatus,
    pollHasCorrectAnswers,
    isCorrect,
    userHasAlreadyAnsweredThisPoll
  }) =>
    pollHasCorrectAnswers &&
    ((pollStatus === 'submitted' && isSelected && isCorrect) ||
      (userHasAlreadyAnsweredThisPoll && isCorrect))
      ? '#33cc66'
      : pollHasCorrectAnswers &&
          ((pollStatus === 'submitted' && isSelected && !isCorrect) ||
            (userHasAlreadyAnsweredThisPoll && !isCorrect))
        ? '#f55151'
        : isSelected
          ? colors.tertiary
          : '#c4c4c4'};
  border-radius: 0.125rem;
  border-style: solid;
  border-width: 0.063rem;
  display: flex;
  height: 1rem;
  justify-content: center;
  margin-right: 0.625rem;
  padding: 0.25rem;
  transition: border-color 150ms ease-in-out;
  width: 1rem;
  > div {
    background-color: ${({
      isSelected,
      colors,
      pollStatus,
      pollHasCorrectAnswers,
      isCorrect,
      userHasAlreadyAnsweredThisPoll
    }) =>
      pollHasCorrectAnswers &&
      ((pollStatus === 'submitted' && isSelected && isCorrect) ||
        (userHasAlreadyAnsweredThisPoll && isCorrect))
        ? '#33cc66'
        : pollHasCorrectAnswers &&
            ((pollStatus === 'submitted' && isSelected && !isCorrect) ||
              (userHasAlreadyAnsweredThisPoll && !isCorrect))
          ? '#f55151'
          : isSelected
            ? colors.tertiary
            : 'transparent'};
    border-radius: 0.125rem;
    flex-shrink: 0;
    height: 0.5rem;
    transition: background-color 150ms ease-in-out;
    width: 0.5rem;
  }
`;

export default GeneralAnswerPollModal;
