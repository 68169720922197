import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

function SvgMinimise(props) {
  const [active, setActive] = useState(false);
  return (
    <StyledSVG
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onMouseDown={() => setActive(true)}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M7.0923 3.03076H11.1538V7.0923M7.0923 11.1538H3.03076V7.0923" stroke="#FFA74A" />
      <motion.path
        animate={{
          x: active ? '-5%' : 0,
          y: active ? '-5%' : 0,
          scale: active ? 0.9 : 1
        }}
        d="M9.12309 12.4463L9.12309 9.12321L12.4462 9.12321"
        stroke="#FFA74A"
      />
      <motion.path
        animate={{
          x: active ? '-5%' : 0,
          y: active ? '-5%' : 0,
          scale: active ? 0.9 : 1
        }}
        d="M13 13.0001L9.12308 9.1232"
        stroke="#FFA74A"
      />
      <motion.path
        animate={{
          x: active ? '5%' : 0,
          y: active ? '5%' : 0,
          scale: active ? 0.9 : 1
        }}
        d="M4.87691 1.55383V4.87691H1.55383"
        stroke="#FFA74A"
      />
      <motion.path
        animate={{
          x: active ? '5%' : 0,
          y: active ? '5%' : 0,
          scale: active ? 0.875 : 1
        }}
        d="M1 1L4.87692 4.87692"
        stroke="#FFA74A"
      />
    </StyledSVG>
  );
}
const StyledSVG = styled(motion.svg)`
  overflow: visible;
`;
export default SvgMinimise;
