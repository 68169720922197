import React, { useContext, useEffect, useRef } from 'react';
import mailcheck from 'mailcheck';
import { Button, ErrorMessage, DialogCloseIcon, CustomError } from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { constructQueryString } from 'utils';
import { fadeInAndOutAndAnimateHeightVariants } from 'styles';
import { FormContainer, FormLabel, FormInput } from '../../FormComponents';

/* eslint-disable no-useless-escape */
const emailAddressIsValidRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable no-useless-escape */

function LoginForm({
  formValues,
  errorMessage,
  setErrorMessage,
  handleInputChange,
  hideCloseIcon,
  colors,
  isDialog,
  shine
}) {
  const {
    siteName,
    magicLinkEmailStatus,
    setMagicLinkEmailStatus,
    handleDialog,
    loginOrSignUpDialogRef,
    setShowLoginOrSignUpDialog,
    showSignUpForm,
    setShowSignUpForm
  } = useContext(LocalContext);
  const { firebase } = useContext(FirebaseContext);

  const emailInputRef = useRef();

  useEffect(() => {
    if (!showSignUpForm) {
      emailInputRef?.current?.focus();
    }
  }, [showSignUpForm]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (errorMessage || magicLinkEmailStatus.sending) {
      return;
    }

    const { email } = formValues;

    try {
      setMagicLinkEmailStatus({
        sending: true,
        sent: false
      });

      mailcheck.run({
        email,
        suggested(suggestion) {
          if (suggestion) {
            throw new CustomError(
              'invalid-argument',
              "Are sure you've spelled the email address correctly?"
            );
          }
        }
      });

      if (!emailAddressIsValidRegex.test(email)) {
        throw new CustomError('invalid-argument', 'Invalid email address.');
      }

      const { data: userExists } = await firebase.registration.checkIfAccountAlreadyExists({
        email
      });

      if (userExists) {
        const userDetailsQueryString = constructQueryString({ formValues });

        // If we're trying to login directly to an event's livestream page and we used
        // window.location.href as the continueUrl below, then the <LoginOrSignUp /> would briefly
        // flash when we're brought directly to the event's livestream page after clicking the magic
        // link in the email. This is because the <ProtectedRoute /> wrapping the event livestream
        // page will always briefly evaluate to !user while Firebase is first loading on client entry.
        // To prevent this we should bring the user to the event registration page (the homepage on
        // the Daiichi Sankyo/AstraZeneca site), which is not wrapped in <ProtectedRoute />, log them in, then redirect
        // them to the livestream page after. Below, 'origin' is the site origin
        // (e.g. http://localhost:8888/ or https://daiichi-medical-education.eventxlive.com/), and url will be the page
        // the user wants to access, such as an event livestream page, or the admin page, etc. We can
        // can parse the 'continueUrl' query param at /email-handler to read this value and
        // navigate to it after logging in.
        const continueUrl = `${window.location.origin}?${userDetailsQueryString}`;

        const actionCodeSettings = {
          url: continueUrl,
          handleCodeInApp: false
        };

        const { data } = await firebase.emails.sendLoginEmail({
          email,
          actionCodeSettings,
          colors,
          origin: window.location.origin,
          pathname: window.location.pathname
        });

        if (data?.Message === 'OK') {
          setMagicLinkEmailStatus({
            sending: false,
            sent: true
          });
          window.localStorage.setItem(`existingUserEmailILDWebinar`, JSON.stringify(email.trim()));
          if (errorMessage) {
            setErrorMessage('');
          }
        }
      } else {
        setMagicLinkEmailStatus({
          sending: false,
          sent: false
        });
        setShowSignUpForm(true);
      }
    } catch (_error) {
      setMagicLinkEmailStatus({
        sending: false,
        sent: false
      });
      switch (_error.code) {
        case 'permission-denied':
          console.error(_error);
          setErrorMessage('Error logging in. Please contact support');
          break;
        default:
          console.error(_error);
          setErrorMessage(_error.message);
          break;
      }
    }
  };

  return (
    <FormContainer
      onSubmit={handleSubmit}
      colors={colors}
      initial={false}
      animate={{ rotateY: showSignUpForm ? -180 : 0 }}
      transition={{
        rotateY: {
          type: 'spring',
          duration: 2,
          stiffness: 60,
          damping: 8,
          mass: 1
        }
      }}
      style={{
        backfaceVisibility: 'hidden',
        position: (isDialog && !showSignUpForm) || !isDialog ? 'absolute' : 'relative',
        padding: magicLinkEmailStatus.sent ? '2rem 1.6rem' : '1rem 1.6rem 2.35rem'
      }}>
      {!hideCloseIcon && (
        <DialogCloseIcon
          onClick={() =>
            handleDialog({
              dialogRef: loginOrSignUpDialogRef,
              animation: magicLinkEmailStatus.sent ? 'close' : 'dismiss',
              stateHandler: () => setShowLoginOrSignUpDialog(false)
            })
          }
          width="0.875em"
        />
      )}
      {!magicLinkEmailStatus.sent && (
        <>
          <FormLabel style={{ margin: '2rem auto', textAlign: 'center' }}>
            Log in or register with your email
          </FormLabel>
          <FormLabel hide htmlFor="email">
            Email
          </FormLabel>
          <FormInput
            id="email"
            name="email"
            onChange={handleInputChange}
            placeholder="Email"
            type="email"
            value={formValues.email}
            required
            ref={emailInputRef}
            style={{
              marginBottom: '1.25rem'
            }}
          />
        </>
      )}
      <ErrorMessage
        errorMessage={errorMessage}
        style={{ color: colors.tertiary }}
        variants={fadeInAndOutAndAnimateHeightVariants()}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row'
        }}>
        {magicLinkEmailStatus.sent ? (
          <h3
            style={{
              fontSize: '1.25rem',
              fontWeight: 600,
              letterSpacing: '0.01em',
              maxWidth: '17em',
              textAlign: 'center'
            }}>
            Please click the link in the email we&apos;ve just sent you to log in.
          </h3>
        ) : (
          <Button
            shine={shine}
            type="submit"
            color="tertiary"
            loadingButton
            loading={magicLinkEmailStatus.sending}
            disabled={errorMessage}
            changeColorWhenDisabled={false}
            style={{
              marginTop: '1.125rem',
              background: 'linear-gradient(90deg, #F01561 0%, #FFA74A 100%)'
            }}>
            Continue
          </Button>
        )}
      </div>
    </FormContainer>
  );
}

export default LoginForm;
