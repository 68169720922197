import React, { useContext } from 'react';
import { ModeratorControlPanel, UserPanel } from 'components';
import { FirebaseContext } from 'context';
import styled from 'styled-components';

function Polls({ colors, eventId, currentlyOpenPoll, setCurrentlyOpenPoll }) {
  const { user } = useContext(FirebaseContext);
  return (
    user && (
      <Wrapper colors={colors}>
        {user?.isModerator?.includes(eventId) ? (
          <ModeratorControlPanel colors={colors} eventId={eventId} />
        ) : (
          <UserPanel
            colors={colors}
            eventId={eventId}
            currentlyOpenPoll={currentlyOpenPoll}
            setCurrentlyOpenPoll={setCurrentlyOpenPoll}
          />
        )}
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 0.9rem 0.75rem 0.5rem;
  position: relative;

  @media screen and (min-width: 410px) {
    padding: 1.125rem 1.0125rem 0.5rem;
  }

  @media screen and (min-width: 1150px) and (max-width: 1365px) {
    padding: 0.9rem 0.75rem 0.5rem;
  }

  @media screen and (min-width: 1366px) {
    padding: 1.125rem 1.0125rem 0.5rem;
  }

  > p:first-child {
    color: ${({ colors }) => colors.tertiary};
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0.1em 0 1.2em;
    position: relative;
    right: 0.023em; /* Have to do this so that it aligns with the "Moderator's Control Panel" in Q&A. TODO: Ideally, I'd stop using scaled3d in the Q&A component, so I don't have to do this here. Will refactor the Q&A component when I get a chance. */
    text-align: center;
  }
`;

export default Polls;
