import React, { useContext, useEffect } from 'react';
import { LoadingSpinner, LoadingSpinnerFullScreenWrapper } from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { useDelayUnmount } from 'hooks';
import { defaultColors } from 'styles';

function ProtectedRoute({ children, openGatedContent = true }) {
  const {
    selectedEvent,
    dialogAnimationTime,
    setShowLoginOrSignUpDialog,
    setShowClosedEventDialog
  } = useContext(LocalContext);
  const { firebase, loading, user } = useContext(FirebaseContext);
  const shouldRenderChild = useDelayUnmount(user, dialogAnimationTime);

  const location = useLocation();
  const { search } = location;

  const queryParams = queryString.parse(search);
  const passwordLogin = queryParams?.passwordLogin;

  useEffect(() => {
    if (!loading && firebase && user && !passwordLogin && !openGatedContent) {
      setShowClosedEventDialog(true);
    } else if (!loading && firebase && !user && !passwordLogin && !shouldRenderChild) {
      setShowLoginOrSignUpDialog(true);
    }
  }, [loading, firebase, user, passwordLogin, shouldRenderChild, openGatedContent]);

  return (
    <>
      {!loading && firebase && (user || shouldRenderChild) && children}
      {!passwordLogin && (
        <AnimatePresence>
          {loading && (
            <LoadingSpinnerFullScreenWrapper
              initial={false}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}>
              <LoadingSpinner
                initial={false}
                style={{
                  width: '8rem',
                  color: selectedEvent ? selectedEvent.colors.tertiary : defaultColors.tertiary
                }}
              />
            </LoadingSpinnerFullScreenWrapper>
          )}
        </AnimatePresence>
      )}
    </>
  );
}

export default ProtectedRoute;
