import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const FormContainer = styled(motion.form)`
  background: ${({ theme }) =>
    theme.className === 'contrast' ? theme.contrast : 'rgba(0, 0, 78, 0.85)'};
  border-radius: 0.625rem;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding: 2.5rem 1rem;
  perspective: 1200px;

  h3,
  label,
  input,
  span,
  p,
  textarea {
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  }
  input,
  span textarea {
    border-color: ${({ theme }) =>
      theme.className === 'contrast' ? theme.primary : theme.contrast};
  }
  input,
  textarea {
    ::placeholder {
      color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
    }
  }
`;

export const FormLabel = styled.h3`
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  line-height: 1.75rem;
  ${({ hide }) =>
    hide
      ? css`
          height: 1px;
          opacity: 0;
          width: 1px;
        `
      : css`
          margin-bottom: 2rem;
        `}
`;

export const FormInputLabel = styled.label`
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  justify-content: flex-start;
  line-height: 19px;
  overflow: hidden;
  width: 100%;
  ${({ style }) => style}
  ${({ hide }) =>
    hide
      ? css`
          height: 1px;
          opacity: 0;
          width: 1px;
        `
      : css`
          margin-bottom: 0.375rem;
        `}
`;

export const FormInput = styled.input`
  align-items: center;
  background: transparent;
  border: 1px solid
    ${({ theme, error }) =>
      error ? '#fd5a45' : theme.className === 'contrast' ? theme.primary : theme.contrast};
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  height: 3.75rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.625rem;
  outline: none;
  padding: 0px 1.25rem;
  width: 100%;

  :focus {
    border: 0.188em solid
      ${({ theme, error }) =>
        error ? '#fd5a45' : theme.className === 'contrast' ? theme.primary : theme.contrast};
  }
  ::placeholder {
    color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
    font-size: 1rem;
    font-style: italic;
    font-weight: 600;
    opacity: 0.65;
  }
`;

export const FormChecks = styled.label`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1.25rem;
  position: relative;
`;

export const FormTerms = styled.span`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.25rem;
`;

export const FormCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0.75rem;
  margin-top: 0.375rem;
  outline: none;
  padding: 0.625rem;
  position: relative;
  transition: 0.5s;
  width: 1.5rem;

  &:checked {
    background-color: ${({ theme }) => theme.tertiary};
    border: ${({ theme }) => `1px solid ${theme.tertiary}`};
  }
  &:checked:before {
    color: ${({ theme }) => theme.contrast};
    content: '✓';
    font-size: 1rem;
    height: 1rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-40%, -60%);
    width: 1rem;
  }
  &:checked ${FormTerms}::before {
    color: #41b883;
    content: '\2713';
    display: block;
    left: 0.7rem;
    position: absolute;
    text-align: center;
    top: 0.2rem;
  }
  &:active {
    border: 0.125rem solid #34495e;
  }
`;
export const FileUpload = styled.label`
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid
    ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 6rem;
  justify-content: center;
  margin-bottom: 1.25rem;
`;

export const ImageUpload = styled.label`
  align-items: center;
  align-self: center;
  border: 1px solid;
  border-radius: 3rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
  width: 100%;
`;

export const HiddenFileUpload = styled.input`
  height: 1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  user-select: none;
  width: 1px;
`;

export const TextArea = styled.textarea`
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  -webkit-letter-spacing: 0.5px;
  background-color: transparent;
  border: 1px solid;
  border-radius: 0px;
  box-sizing: border-box;
  color: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : theme.contrast)};
  font-size: 1rem;
  font-weight: 600;
  height: 10rem;
  letter-spacing: 0.5px;
  margin-bottom: 1.5rem;
  padding: 0.75rem 1.25rem;
  resize: none;
  width: 100%;
  ::placeholder {
    color: white !important;
    font-style: italic;
  }
  :focus {
    outline: none;
  }
`;
