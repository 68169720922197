import React, { useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Tick } from 'assets/dynamic-svgs';

function FeedbackOptions({
  colors,
  allowPersonalFeedbackMessage,
  setAllowPersonalFeedbackMessage,
  allowContactField,
  setAllowContactField,
  ratingOption,
  setRatingOption,
  setSelectedRatingType,
  ratingAmount,
  setRatingAmount
}) {
  const numbers = [3, 4, 5, 6, 7, 8, 9, 10];
  const [isRatingDropdownMenuOpen, setIsRatingDropdownMenuOpen] = useState(false);

  return (
    <Wrapper>
      <RatingOptions>
        <span>Choose rating option:</span>
        <Option
          isSelected={ratingOption.stars}
          onClick={() => {
            setRatingOption({ stars: true, emojis: false, numbers: false });
            setSelectedRatingType('stars');
          }}>
          <RatingCheckboxInput isSelected={ratingOption.stars} colors={colors}>
            <div>
              <AnimatePresence>
                {ratingOption.stars && <Tick color={colors.secondary} />}
              </AnimatePresence>
            </div>
          </RatingCheckboxInput>
          Stars
          {ratingOption.stars && (
            <MultipleOptionsSelector
              colors={colors}
              onBlur={() => setIsRatingDropdownMenuOpen(false)}
              onClick={() => setIsRatingDropdownMenuOpen(true)}
              tabIndex={0}>
              {ratingAmount}
              <div
                style={{
                  borderLeft: '0.25em solid transparent',
                  borderRight: '0.25em solid transparent',
                  height: 0,
                  marginLeft: '0.417em',
                  width: 0
                }}
              />
              <AnimatePresence>
                {isRatingDropdownMenuOpen && (
                  <MultipleOptionsDropdownMenu
                    colors={colors}
                    onClick={(e) => {
                      e.stopPropagation();
                      setRatingAmount(e.target.innerHTML);
                      setIsRatingDropdownMenuOpen(false);
                    }}>
                    {numbers.map((number) => (
                      <div key={number}>{number}</div>
                    ))}
                  </MultipleOptionsDropdownMenu>
                )}
              </AnimatePresence>
            </MultipleOptionsSelector>
          )}
        </Option>
        <Option
          isSelected={ratingOption.emojis}
          onClick={() => {
            setRatingOption({ stars: false, emojis: true, numbers: false });
            setSelectedRatingType('emojis');
            setRatingAmount('5');
          }}>
          <RatingCheckboxInput isSelected={ratingOption.emojis} colors={colors}>
            <div>
              <AnimatePresence>
                {ratingOption.emojis && <Tick color={colors.secondary} />}
              </AnimatePresence>
            </div>
          </RatingCheckboxInput>
          Emojis
          {ratingOption.emojis && (
            <MultipleOptionsSelector
              colors={colors}
              onBlur={() => setIsRatingDropdownMenuOpen(false)}
              onClick={() => setIsRatingDropdownMenuOpen(true)}
              tabIndex={0}>
              {ratingAmount}
              <div
                style={{
                  borderLeft: '0.25em solid transparent',
                  borderRight: '0.25em solid transparent',
                  height: 0,
                  marginLeft: '0.417em',
                  width: 0
                }}
              />
              <AnimatePresence>
                {isRatingDropdownMenuOpen && (
                  <MultipleOptionsDropdownMenu
                    colors={colors}
                    onClick={(e) => {
                      e.stopPropagation();
                      setRatingAmount(e.target.innerHTML);
                      setIsRatingDropdownMenuOpen(false);
                    }}>
                    <div>{3}</div>
                    <div>{5}</div>
                  </MultipleOptionsDropdownMenu>
                )}
              </AnimatePresence>
            </MultipleOptionsSelector>
          )}
        </Option>
        <Option
          isSelected={ratingOption.numbers}
          onClick={() => {
            setRatingOption({ stars: false, emojis: false, numbers: true });
            setSelectedRatingType('numbers');
          }}>
          <RatingCheckboxInput isSelected={ratingOption.numbers} colors={colors}>
            <div>
              <AnimatePresence>
                {ratingOption.numbers && <Tick color={colors.secondary} />}
              </AnimatePresence>
            </div>
          </RatingCheckboxInput>
          Numbers
          {ratingOption.numbers && (
            <MultipleOptionsSelector
              colors={colors}
              onBlur={() => setIsRatingDropdownMenuOpen(false)}
              onClick={() => setIsRatingDropdownMenuOpen(true)}
              tabIndex={0}>
              {ratingAmount}
              <div
                style={{
                  borderLeft: '0.25em solid transparent',
                  borderRight: '0.25em solid transparent',
                  height: 0,
                  marginLeft: '0.417em',
                  width: 0
                }}
              />
              <AnimatePresence>
                {isRatingDropdownMenuOpen && (
                  <MultipleOptionsDropdownMenu
                    colors={colors}
                    onClick={(e) => {
                      e.stopPropagation();
                      setRatingAmount(e.target.innerHTML);
                      setIsRatingDropdownMenuOpen(false);
                    }}>
                    {numbers.map((number) => (
                      <div key={number}>{number}</div>
                    ))}
                  </MultipleOptionsDropdownMenu>
                )}
              </AnimatePresence>
            </MultipleOptionsSelector>
          )}
        </Option>
      </RatingOptions>
      <Option onClick={() => setAllowPersonalFeedbackMessage((currentState) => !currentState)}>
        <OptionCheckbox colors={colors} isEnabled={allowPersonalFeedbackMessage}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Allow personal feedback message
      </Option>
      <Option
        colors={colors}
        style={{
          marginBottom: '0.25rem'
        }}
        onClick={() => setAllowContactField((currentState) => !currentState)}>
        <OptionCheckbox colors={colors} isEnabled={allowContactField}>
          <div>
            <div />
          </div>
        </OptionCheckbox>
        Include{' '}
        <span style={{ whiteSpace: 'nowrap' }}>&nbsp;&apos;Can we contact you?&apos;&nbsp;</span>{' '}
        field
      </Option>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  left: -0.375rem;
  margin-bottom: 0.25rem;
  position: relative;
`;

const Option = styled(motion.div)`
  align-items: center;
  color: #4f4f4f;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  opacity: ${({ isSelected }) => (isSelected || typeof isSelected === 'undefined' ? 1 : 0.5)};
  width: fit-content;
  span {
    color: rgba(0, 127, 192, 0.7);
  }
`;

const OptionCheckbox = styled.div`
  cursor: pointer;
  margin-right: 8px;
  padding: 6px;

  > div {
    align-items: center;
    border: 1px solid ${({ isEnabled, colors }) => (isEnabled ? colors.secondary : '#c4c4c4')};
    display: flex;
    height: 1rem;
    justify-content: center;
    transition: border 200ms ease-in-out;
    width: 1rem;

    > div {
      background-color: ${({ isEnabled, colors }) =>
        isEnabled ? colors.secondary : 'transparent'};
      height: 8px;
      transition: background-color 200ms ease-in-out;
      width: 8px;
    }

    &,
    > div {
      border-radius: 2px;
    }
  }
`;

const RatingOptions = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  span {
    color: #4f4f4f;
    font-size: 0.875rem;
    padding: 0 0 1rem 0.3rem;
  }
`;

const RatingCheckboxInput = styled.div`
  left: 0;
  padding: 6px;
  top: -0.1rem;

  > div {
    border-color: ${({ isSelected, colors }) =>
      isSelected ? colors.secondary : '#bdbdbd'};
    border-radius: 50%;
    border-style: solid;
    border-width: 0.063rem;
    height: 1rem;
    pointer-events: none;
    transition: border-color 100ms ease-in-out;
    width: 1rem;

    svg {
      bottom: 1px;
      left: 2px;
      pointer-events: none;
      position: relative;
      width: 11px;
    }
  }
`;

const MultipleOptionsSelector = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  align-items: center;
  border-radius: 14px;
  border: 1px solid #c4c4c4;
  display: flex;
  font-size: 0.75rem;
  height: 16px;
  justify-content: center;
  margin-left: 10px;
  padding-left: 1px;
  transition: color 2000ms solid ease-in-out;
  outline: none;
  position: relative;
  width: 41px;

  > div:first-of-type {
    border-top: 0.188rem solid #4f4f4f;
    transition: border-top 200ms solid ease-in-out;
  }

  :focus {
    border: 1px solid ${({ colors }) => colors.secondary};
    color: ${({ colors }) => colors.secondary};

    > div:first-of-type {
      border-top: 0.188rem solid ${({ colors }) => colors.secondary};
    }
  }
`;

const MultipleOptionsDropdownMenu = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  align-items: center;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 12px 2px rgba(196, 196, 196, 0.6);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  left: 8px;
  padding: 4px 0;
  position: absolute;
  top: 5px;
  width: 35px;
  z-index: 100;
  > div {
    align-items: center;
    color: #4f4f4f;
    display: flex;
    font-size: 12px;
    height: 20.5px;
    justify-content: center;
    width: 100%;

    &:hover {
      color: ${({ colors }) => colors.secondary};
    }
  }
`;

export default FeedbackOptions;
