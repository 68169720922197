import React from 'react';
import { Button } from 'components';
import styled from 'styled-components';
import { Markup } from 'interweave';
import { FormContainer } from '../FormComponents';

function NewUserEmailVerified({ user, closeNewUserEmailVerifiedDialog }) {
  return (
    <FormContainer style={{ margin: '0 auto' }}>
      {user?.name ? (
        <>
          <H3>
            <Markup
              content={`Thanks, ${user?.name?.split(' ')[0]}.<br>You're free to explore!<br><br>`}
              noWrap
            />
          </H3>
          {user?.name && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={closeNewUserEmailVerifiedDialog} type="button">
                Continue
              </Button>
            </div>
          )}
        </>
      ) : (
        <H3>Verifying Email</H3>
      )}
    </FormContainer>
  );
}

const H3 = styled.h3`
  font-size: 1.75rem;
  line-height: 1.275em;
  text-align: center;
`;

export default NewUserEmailVerified;
