import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { FirebaseContext, LocalContext } from 'context';
import { updateEmail } from 'firebase/auth';
import { hexToRGB } from 'utils';
import { LoadingSpinnerFeedback, Button } from 'components';
import { FormContainer, FormInput, FormInputLabel, HiddenFileUpload } from '../../FormComponents';
import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon } from '../../../../assets/svgs';

let fileReader;

if (typeof window !== 'undefined') {
  fileReader = new FileReader();
}

function EditProfile({
  avatar,
  setAvatar,
  saveStates,
  setSaveStates,
  error,
  setError,
  savingAvatar,
  setSavingAvatar
}) {
  const { user, firebase } = useContext(FirebaseContext);
  const { theme } = useContext(LocalContext);
  const timeToWaitBeforeSavingChanges = 750;
  const [currentFormInput, setCurrentFormInput] = useState('');

  const formInputLoadingSpinnerStyle = {
    position: 'absolute',
    color: theme.primary,
    top: '0.6rem',
    left: 'calc(100% - 2.25rem)',
    width: '1.5rem'
  };

  const [formValues, setFormValues] = useState({
    avatarFile: '',
    name: user.name,
    email: user.email,
    profession: user.profession,
    company: user.company,
    socials: {
      facebook: user.socials?.facebook,
      twitter: user.socials?.twitter,
      linkedIn: user.socials?.linkedIn,
      instagram: user.socials?.instagram
    }
  });

  useEffect(() => {
    fileReader.addEventListener('load', () => {
      const avatarFile = fileReader.result;
      setFormValues((currentStates) => ({
        ...currentStates,
        avatarFile
      }));
    });
  }, []);

  useEffect(() => {
    if (formValues.avatarFile) {
      setSaveStates((currentState) => ({
        ...currentState,
        avatar: 'saving'
      }));
      setSavingAvatar(true);

      /* We wait 2.5 seconds, just to buy the backend some time uploading the image and resizing it.
      Othwerise the user might wonder why the avatar has updated instantly on their profile popup,
      but not in their navbar or in any comments. */
      setTimeout(() => {
        /* We set the local file in memory as the avatar here first, as the file is still being
        resized and uploaded to the database for persistence. */
        setAvatar(formValues.avatarFile);

        setSaveStates((currentState) => ({
          ...currentState,
          avatar: 'saved'
        }));
        setSavingAvatar(false);
      }, 2500);

      firebase.userProfile
        .uploadAvatar(formValues.avatarFile)
        .then(async () => firebase.auth.currentUser.reload());
    }
  }, [formValues.avatarFile]);

  useEffect(() => {
    if (user.avatarUrl !== avatar) {
      setAvatar(user.avatarUrl);
    }
  }, [user.avatarUrl]);

  useEffect(() => {
    if (formValues.name !== user.name) {
      setFormValues((currentStates) => ({
        ...currentStates,
        name: user.name
      }));
    }
  }, [user.name]);

  useEffect(() => {
    if (formValues.email !== user.email) {
      setFormValues((currentStates) => ({
        ...currentStates,
        email: user.email
      }));
    }
  }, [user.email]);

  useEffect(() => {
    if (formValues.profession !== user.profession) {
      setFormValues((currentValues) => ({
        ...currentValues,
        profession: user.profession
      }));
    }
  }, [user.profession]);

  useEffect(() => {
    if (formValues.company !== user.company) {
      setFormValues((currentStates) => ({
        ...currentStates,
        company: user.company
      }));
    }
  }, [user.company]);

  useEffect(() => {
    if (formValues.facebook !== user.socials?.facebook) {
      setFormValues((currentState) => ({
        ...currentState,
        socials: {
          ...currentState.socials,
          facebook: user.socials?.facebook
        }
      }));
    }
  }, [user.socials?.facebook]);

  useEffect(() => {
    if (formValues.linkedIn !== user.socials?.linkedIn) {
      setFormValues((currentState) => ({
        ...currentState,
        socials: {
          ...currentState.socials,
          linkedIn: user.socials?.linkedIn
        }
      }));
    }
  }, [user.socials?.linkedIn]);

  useEffect(() => {
    if (formValues.twitter !== user.socials?.twitter) {
      setFormValues((currentState) => ({
        ...currentState,
        socials: {
          ...currentState.socials,
          twitter: user.socials?.twitter
        }
      }));
    }
  }, [user.socials?.twitter]);

  useEffect(() => {
    if (formValues.instagram !== user.socials?.instagram) {
      setFormValues((currentState) => ({
        ...currentState,
        socials: {
          ...currentState.socials,
          instagram: user.socials?.instagram
        }
      }));
    }
  }, [user.socials?.instagram]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (formValues.name !== user.name) {
        setSaveStates((currentStates) => ({
          ...currentStates,
          name: 'saving'
        }));
      } else if (formValues.name === user.name) {
        setSaveStates((currentStates) => ({
          ...currentStates,
          name: null
        }));
      }

      if (error.message) {
        setError({
          code: '',
          message: ''
        });
      }

      const handleUpdateName = async () => {
        await firebase.userProfile
          .updateName({ uid: user.uid, name: formValues.name })
          .then(() => {
            setSaveStates((currentStates) => ({
              ...currentStates,
              name: 'saved'
            }));
          })
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
            setSaveStates((currentStates) => ({
              ...currentStates,
              name: 'not-saved'
            }));
          });
      };

      if (formValues.name !== '' && formValues.name !== user.name) {
        handleUpdateName();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [formValues.name]);

  // Disabling the ability to change email until we find a working solution
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (formValues.email !== user.email) {
  //       setSaveStates((currentStates) => ({
  //         ...currentStates,
  //         email: 'saving'
  //       }));
  //     } else if (formValues.email === user.email) {
  //       setSaveStates((currentStates) => ({
  //         ...currentStates,
  //         email: null
  //       }));
  //     }

  //     if (error.message) {
  //       setError({
  //         code: '',
  //         message: ''
  //       });
  //     }

  //     const handleUpdateEmail = async () => {
  //       // This first updateEmail() updates the email in Firebase Auth, whereas
  //       // firebase.userProfile.updateEmail() updates the email in Firestore.
  //       await updateEmail(firebase.auth.currentUser, formValues.email)
  //         .then(() => firebase.userProfile.updateEmail({ uid: user.uid, email: formValues.email }))
  //         .then(() =>
  //           setSaveStates((currentStates) => ({
  //             ...currentStates,
  //             email: 'saved'
  //           }))
  //         )
  //         .catch((err) => {
  //           console.error(err);
  //           switch (err.code) {
  //             case 'auth/requires-recent-login':
  //               setError({
  //                 code: err.code,
  //                 message:
  //                   'Changing your email address is a sensitive operation and requires recent authentication.<br />Please log out and then log in again before retrying.'
  //               });
  //               break;
  //             default:
  //               setError({
  //                 code: err.code,
  //                 message: err.message
  //               });
  //               break;
  //           }
  //           setSaveStates((currentStates) => ({
  //             ...currentStates,
  //             email: 'not-saved'
  //           }));
  //         });
  //     };

  //     if (formValues.email !== '' && formValues.email !== user.email) {
  //       handleUpdateEmail();
  //     }
  //   }, timeToWaitBeforeSavingChanges);

  //   return () => clearTimeout(timer);
  // }, [formValues.email]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (formValues.profession !== user.profession) {
        setSaveStates((currentStates) => ({
          ...currentStates,
          profession: 'saving'
        }));
      } else if (formValues.profession === user.profession) {
        setSaveStates((currentStates) => ({
          ...currentStates,
          profession: null
        }));
      }

      if (error.message) {
        setError({
          code: '',
          message: ''
        });
      }

      const handleUpdateProfession = async () => {
        await firebase.userProfile
          .updateProfession({ uid: user.uid, profession: formValues.profession })
          .then(() => {
            setSaveStates((currentStates) => ({
              ...currentStates,
              profession: 'saved'
            }));
          })
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
            setSaveStates((currentStates) => ({
              ...currentStates,
              profession: 'not-saved'
            }));
          });
      };

      if (formValues.profession !== user.profession) {
        handleUpdateProfession();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [formValues.profession]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (formValues.company !== user.company) {
        setSaveStates((currentStates) => ({
          ...currentStates,
          company: 'saving'
        }));
      } else if (formValues.company === user.company) {
        setSaveStates((currentStates) => ({
          ...currentStates,
          company: null
        }));
      }

      if (error.message) {
        setError({
          code: '',
          message: ''
        });
      }

      const handleUpdateCompany = async () => {
        await firebase.userProfile
          .updateCompany({ uid: user.uid, company: formValues.company })
          .then(() => {
            setSaveStates((currentStates) => ({
              ...currentStates,
              company: 'saved'
            }));
          })
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
            setSaveStates((currentStates) => ({
              ...currentStates,
              company: 'not-saved'
            }));
          });
      };
      if (formValues.company !== '' && formValues.company !== user.company) {
        handleUpdateCompany();
      }
    }, timeToWaitBeforeSavingChanges);
    return () => clearTimeout(timer);
  }, [formValues.company]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (user.socials && formValues[currentFormInput] !== user.socials[currentFormInput]) {
        setSaveStates((currentStates) => ({
          ...currentStates,
          socials: {
            ...currentStates.socials,
            [currentFormInput]: 'saving'
          }
        }));
      } else if (user.socials && formValues[currentFormInput] === user.socials[currentFormInput]) {
        setSaveStates((currentStates) => ({
          ...currentStates,
          socials: {
            ...currentStates.socials,
            [currentFormInput]: null
          }
        }));
      }

      if (error.message) {
        setError({
          code: '',
          message: ''
        });
      }

      const handleUpdateSocials = async () => {
        setSaveStates((currentStates) => ({
          ...currentStates,
          socials: {
            ...currentStates.socials,
            [currentFormInput]: 'saving'
          }
        }));
        await firebase.userProfile
          .updateSocials({
            uid: user.uid,
            socials: {
              facebook: formValues.socials.facebook,
              linkedIn: formValues.socials.linkedIn,
              twitter: formValues.socials.twitter,
              instagram: formValues.socials.instagram
            }
          })
          .then(() =>
            setSaveStates((currentStates) => ({
              ...currentStates,
              socials: {
                ...currentStates.socials,
                [currentFormInput]: 'saved'
              }
            }))
          )
          .catch((err) => {
            console.error(err);
            setError({
              code: err.code,
              message: err.message
            });
            setSaveStates((currentStates) => ({
              ...currentStates,
              socials: {
                ...currentStates.socials,
                [currentFormInput]: 'not-saved'
              }
            }));
          });
      };

      if (
        formValues.socials.facebook !== user.socials?.facebook ||
        formValues.socials.linkedIn !== user.socials?.linkedIn ||
        formValues.socials.twitter !== user.socials?.twitter ||
        formValues.socials.instagram !== user.socials?.instagram
      ) {
        handleUpdateSocials();
      }
    }, timeToWaitBeforeSavingChanges);

    return () => clearTimeout(timer);
  }, [
    formValues.socials.facebook,
    formValues.socials.linkedIn,
    formValues.socials.twitter,
    formValues.socials.instagram
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'facebook' || name === 'linkedIn' || name === 'twitter' || name === 'instagram') {
      setFormValues((currentValues) => ({
        ...currentValues,
        socials: {
          ...currentValues.socials,
          [name]:
            value && (name === 'twitter' || name === 'instagram')
              ? value.charAt(0) === '@'
                ? value
                : `@${value}`
              : value
        }
      }));
      setCurrentFormInput(name);
    } else {
      setFormValues((currentValues) => ({
        ...currentValues,
        [name]: value
      }));
    }
  };

  return (
    <CustomFormContainer>
      <SetOrChangeAvatarButton
        htmlFor="avatarFile"
        savingAvatar={savingAvatar}
        whileTap={{ scale: savingAvatar ? 1 : 0.95 }}>
        {savingAvatar ? (
          <UploadingAvatarMessage>Uploading</UploadingAvatarMessage>
        ) : (
          `${avatar ? 'Change' : 'Set'} Profile Photo`
        )}
      </SetOrChangeAvatarButton>
      <HiddenFileUpload
        type="file"
        name="avatarFile"
        id="avatarFile"
        disabled={savingAvatar}
        onChange={(e) => e.target.files.length && fileReader.readAsDataURL(e.target.files[0])}
      />
      <FormInputLabel htmlFor="name" style={{ fontWeight: 400, padding: '0 0 0.25rem 0' }}>
        Full Name
      </FormInputLabel>
      <FormInputContainer>
        <FormInput
          id="name"
          name="name"
          type="text"
          value={formValues.name}
          onChange={handleInputChange}
          autoComplete="off"
        />
        <AnimatePresence>
          {saveStates.name !== null && (
            <LoadingSpinnerFeedback
              bottom
              style={formInputLoadingSpinnerStyle}
              loading={saveStates.name === 'saving'}
              completed={saveStates.name === 'saved'}
              error={saveStates.name === 'not-saved'}
            />
          )}
        </AnimatePresence>
      </FormInputContainer>
      <AnimatePresence>
        {saveStates.name === 'not-saved' && (
          <ErrorMessage
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}>
            Something went wrong, try again.
          </ErrorMessage>
        )}
      </AnimatePresence>
      <FormInputLabel
        htmlFor="profession"
        style={{ fontWeight: 400, padding: '1.25rem 0 0.25rem 0' }}>
        Profession/Role
      </FormInputLabel>
      <FormInputContainer>
        <FormInput
          id="profession"
          name="profession"
          type="text"
          value={formValues.profession}
          onChange={handleInputChange}
          autoComplete="off"
        />
        <AnimatePresence>
          {saveStates.profession !== null && (
            <LoadingSpinnerFeedback
              bottom
              style={formInputLoadingSpinnerStyle}
              loading={saveStates.profession === 'saving'}
              completed={saveStates.profession === 'saved'}
              error={saveStates.profession === 'not-saved'}
            />
          )}
        </AnimatePresence>
      </FormInputContainer>
      <AnimatePresence>
        {saveStates.profession === 'not-saved' && (
          <ErrorMessage
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}>
            Something went wrong, try again.
          </ErrorMessage>
        )}
      </AnimatePresence>
      <FormInputLabel htmlFor="company" style={{ fontWeight: 400, padding: '1.25rem 0 0.25rem 0' }}>
        Company/Workplace
      </FormInputLabel>
      <FormInputContainer>
        <FormInput
          id="company"
          name="company"
          type="text"
          value={formValues.company}
          onChange={handleInputChange}
          autoComplete="off"
        />
        <AnimatePresence>
          {saveStates.company !== null && (
            <LoadingSpinnerFeedback
              bottom
              style={formInputLoadingSpinnerStyle}
              loading={saveStates.company === 'saving'}
              completed={saveStates.company === 'saved'}
              error={saveStates.company === 'not-saved'}
            />
          )}
        </AnimatePresence>
      </FormInputContainer>
      <AnimatePresence>
        {saveStates.company === 'not-saved' && (
          <ErrorMessage
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}>
            Something went wrong, try again.
          </ErrorMessage>
        )}
      </AnimatePresence>
      <FormInputLabel htmlFor="social" style={{ fontWeight: 400, padding: '1.25rem 0 0.25rem 0' }}>
        Social Media Channels
      </FormInputLabel>
      <SocialFormInputs>
        <div>
          <Icon>
            <FacebookIcon />
          </Icon>
          <FormInput
            id="social"
            name="facebook"
            type="text"
            value={formValues.socials.facebook}
            onChange={handleInputChange}
            placeholder="Facebook Profile URL"
            autoComplete="off"
          />
          <AnimatePresence>
            {saveStates.socials.facebook !== null && (
              <LoadingSpinnerFeedback
                style={formInputLoadingSpinnerStyle}
                loading={saveStates.socials.facebook === 'saving'}
                completed={saveStates.socials.facebook === 'saved'}
                error={saveStates.socials.facebook === 'not-saved'}
              />
            )}
          </AnimatePresence>
        </div>
        <AnimatePresence>
          {saveStates.socials.facebook === 'not-saved' && (
            <ErrorMessage
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}>
              Something went wrong, try again.
            </ErrorMessage>
          )}
        </AnimatePresence>
        <div>
          <Icon>
            <LinkedinIcon />
          </Icon>
          <FormInput
            id="social"
            name="linkedIn"
            type="text"
            value={formValues.socials.linkedIn}
            onChange={handleInputChange}
            placeholder="LinkedIn Profile URL"
            autoComplete="off"
          />
          <AnimatePresence>
            {saveStates.socials.linkedIn !== null && (
              <LoadingSpinnerFeedback
                style={formInputLoadingSpinnerStyle}
                loading={saveStates.socials.linkedIn === 'saving'}
                completed={saveStates.socials.linkedIn === 'saved'}
                error={saveStates.socials.linkedIn === 'not-saved'}
              />
            )}
          </AnimatePresence>
        </div>
        <AnimatePresence>
          {saveStates.socials.linkedIn === 'not-saved' && (
            <ErrorMessage
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}>
              Something went wrong, try again.
            </ErrorMessage>
          )}
        </AnimatePresence>
        <div>
          <Icon>
            <TwitterIcon />
          </Icon>
          <FormInput
            id="social"
            name="twitter"
            type="text"
            value={formValues.socials.twitter}
            onChange={handleInputChange}
            placeholder="Twitter Handle"
            autoComplete="off"
          />
          <AnimatePresence>
            {saveStates.socials.twitter !== null && (
              <LoadingSpinnerFeedback
                style={formInputLoadingSpinnerStyle}
                loading={saveStates.socials.twitter === 'saving'}
                completed={saveStates.socials.twitter === 'saved'}
                error={saveStates.socials.twitter === 'not-saved'}
              />
            )}
          </AnimatePresence>
        </div>
        <AnimatePresence>
          {saveStates.socials.twitter === 'not-saved' && (
            <ErrorMessage
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}>
              Something went wrong, try again.
            </ErrorMessage>
          )}
        </AnimatePresence>
        <div>
          <Icon>
            <InstagramIcon />
          </Icon>
          <FormInput
            id="social"
            name="instagram"
            type="text"
            value={formValues.socials.instagram}
            onChange={handleInputChange}
            placeholder="Instagram Handle"
            autoComplete="off"
          />
          <AnimatePresence>
            {saveStates.socials.instagram !== null && (
              <LoadingSpinnerFeedback
                style={formInputLoadingSpinnerStyle}
                loading={saveStates.socials.instagram === 'saving'}
                completed={saveStates.socials.instagram === 'saved'}
                error={saveStates.socials.instagram === 'not-saved'}
              />
            )}
          </AnimatePresence>
        </div>
        <AnimatePresence>
          {saveStates.socials.instagram === 'not-saved' && (
            <ErrorMessage
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}>
              Something went wrong, try again.
            </ErrorMessage>
          )}
        </AnimatePresence>
      </SocialFormInputs>
    </CustomFormContainer>
  );
}

const CustomFormContainer = styled(FormContainer)`
  background-color: transparent;
  border-radius: 0;
  margin-bottom: 1.25rem;
  padding: 0 !important;
  width: 100%;
  h3,
  label,
  input,
  span,
  p,
  textarea {
    color: ${({ theme }) => theme.primary};
  }
  input,
  span textarea {
    border-color: ${({ theme }) => theme.primary};
  }
  label {
    &:not(:first-of-type) {
      font-weight: 300 !important;
    }
  }
  input,
  textarea {
    ::placeholder {
      color: ${({ theme }) => hexToRGB({ color: theme.primary, alpha: 0.5 })};
      font-weight: 300;
    }
    :focus {
      border-color: ${({ theme }) => theme.primary};
    }
  }
  @media only screen and (min-width: 1440px) {
    padding: 0 !important;
  }
`;

const SetOrChangeAvatarButton = styled(motion.label)`
  align-items: center;
  align-self: center;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 3.125rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.primary};
  cursor: ${({ savingAvatar }) => (savingAvatar ? 'default' : 'pointer')};
  display: flex;
  font-weight: 700 !important;
  height: 1.5rem;
  justify-content: center;
  margin-bottom: 2rem;
  padding: ${({ savingAvatar }) => (savingAvatar ? '0.925rem 1.875rem' : '0.925rem 1.25rem')};
  text-transform: uppercase;
  user-select: none;
`;

const UploadingAvatarMessage = styled.span`
  &:after {
    animation: dots 1s steps(5, end) infinite;
    content: ' .';
    margin-left: -0.175rem;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        0.35rem 0 0 rgba(0, 0, 0, 0),
        0.7rem 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: ${({ theme }) => theme.primary};
      text-shadow:
        0.35rem 0 0 rgba(0, 0, 0, 0),
        0.7rem 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow:
        0.35rem 0 0 ${({ theme }) => theme.primary},
        0.7rem 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow:
        0.35rem 0 0 ${({ theme }) => theme.primary},
        0.7rem 0 0 ${({ theme }) => theme.primary};
    }
  }
`;

const Icon = styled.div`
  height: 24px;
  width: 24px;
  svg {
    height: 100%;
    width: auto;
    circle {
      fill: ${({ theme }) => theme.primary};
    }
    path {
      fill: ${({ theme }) => theme.contrast};
    }
  }
`;

const SocialFormInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  div {
    align-items: center;
    display: flex;
    gap: 1rem;
    position: relative;
    input {
      height: 2.75rem;
      margin-bottom: 0;
    }
  }
`;

const FormInputContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  height: 100%;
  position: relative;
  width: 100%;
  ${({ style }) => style};
  input {
    height: 2.75rem;
    margin-bottom: 0;
    width: 100%;
  }
`;

const ErrorMessage = styled(motion.p)`
  color: #fd5a45 !important;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  text-align: right;
`;

export default EditProfile;
