import React, { useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { SpeakerCard, Dialog, SpeakerCarousel } from 'components';
import { LocalContext } from 'context';
import { AnimatePresence } from 'framer-motion';

function Speakers({ speakers, eventId, colors }) {
  const { theme, selectedEvent, handleDialog, speakersRef } = useContext(LocalContext);
  const [showSpeakerCarouselDialog, setShowSpeakerCarouselDialog] = useState(false);
  const [openSpeakerCarouselAtThisIndex, setOpenSpeakerCarouselAtThisIndex] = useState(null);
  const speakerCarouselDialogRef = useRef(null);
  return (
    <>
      <Container ref={speakersRef}>
        <SpeakersList>
          <AnimatePresence>
            {speakers.map((speaker) => (
              <SpeakerCard
                key={speaker.speakerId}
                speaker={speaker}
                speakers={speakers}
                eventId={eventId}
                setShowSpeakerCarouselDialog={setShowSpeakerCarouselDialog}
                setOpenSpeakerCarouselAtThisIndex={setOpenSpeakerCarouselAtThisIndex}
                speakerCarouselDialogRef={speakerCarouselDialogRef}
              />
            ))}
          </AnimatePresence>
        </SpeakersList>
      </Container>
      <Dialog
        ref={speakerCarouselDialogRef}
        isVisible={showSpeakerCarouselDialog}
        onDismiss={() =>
          handleDialog({
            dialogRef: speakerCarouselDialogRef,
            animation: 'dismiss',
            stateHandler: () => setShowSpeakerCarouselDialog(false),
            callback: () => setOpenSpeakerCarouselAtThisIndex(null)
          })
        }
        contentStyle={{
          maxWidth: '100%'
        }}>
        <SpeakerCarousel
          selectedEvent={selectedEvent}
          openSpeakerCarouselAtThisIndex={openSpeakerCarouselAtThisIndex}
          speakers={speakers}
          colors={colors}
          eventId={eventId}
          closeSpeakerCarouselDialog={() =>
            handleDialog({
              dialogRef: speakerCarouselDialogRef,
              animation: 'close',
              stateHandler: () => setShowSpeakerCarouselDialog(false),
              callback: () => setOpenSpeakerCarouselAtThisIndex(null)
            })
          }
        />
      </Dialog>
    </>
  );
}

const Container = styled.section`
  grid-column: 1/-1;
  margin: 0 auto;
  max-width: 77rem;
  padding: 0 1rem;
  width: 100%;
`;

const SpeakersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  list-style-type: none;
  @media only screen and (min-width: 1150px) {
    gap: 3rem;
  }
`;

const H5 = styled.h5`
  color: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 0.5em;
  margin-top: 0px;
  @media only screen and (min-width: 1150px) {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }
`;

export default Speakers;
