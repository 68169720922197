import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { hexToRGB } from 'utils';
import { motion } from 'framer-motion';
import { FirebaseContext } from 'context';

function QueuedOrClosedPoll({
  eventId,
  colors,
  poll,
  selectedPoll,
  setSelectedPoll,
  setIsCreateOrEditPollModalOpen,
  setPollBeingCurrentlyEditedByModerator,
  setPollAnalyticsBeingCurrentlyViewedByModerator,
  setIsAnalyticsModalOpen,
  setPollToBeDeleted,
  setShowConfirmDeleteDialog
}) {
  const { user } = useContext(FirebaseContext);

  const handleViewAnalytics = (e) => {
    e.stopPropagation();
    setPollAnalyticsBeingCurrentlyViewedByModerator(poll);
    setIsAnalyticsModalOpen(true);
  };

  const handleEditPoll = (e) => {
    e.stopPropagation();
    if (poll.isOpen) {
      return;
    }
    setPollBeingCurrentlyEditedByModerator(poll);
    setIsCreateOrEditPollModalOpen(true);
  };

  return (
    <Wrapper
      layout
      colors={colors}
      isModerator={user?.isModerator?.includes(eventId)}
      isSelected={selectedPoll?.pollId === poll.pollId}
      isCurrentlyOpen={poll.isOpen}
      moderatorIsCurrentlySharingThisPollsAnalyticsWithUsers={poll.shareResults}
      onClick={() => {
        if (selectedPoll?.pollId === poll.pollId) {
          setSelectedPoll(null);
        } else {
          setSelectedPoll(poll);
        }
      }}
      initial={{
        opacity: 0,
        height: 0
      }}
      animate={{
        opacity: 1,
        height: 'auto',
        transition: {
          type: 'tween',
          ease: 'easeInOut',
          height: {
            duration: 0.4
          },
          opacity: {
            duration: 0.65,
            delay: 0.1
          }
        }
      }}
      exit={{
        opacity: 0,
        height: 0,
        transition: {
          type: 'tween',
          ease: 'easeInOut',
          height: {
            duration: 0.4,
            delay: 0.55
          },
          opacity: {
            duration: 0.65
          }
        }
      }}>
      <div>
        <SelectPollCheckbox
          checked={selectedPoll?.pollId === poll.pollId}
          id="selectPollCheckbox"
          name="selectPollCheckbox"
          colors={colors}
          type="checkbox"
          readOnly
        />
        <p
          style={{
            fontSize: '14px',
            marginTop: '6px',
            fontStyle: 'italic',
            textTransform: 'capitalize'
          }}>
          {poll.type}
        </p>
        <p>{poll.question.text}</p>
        <p>
          {poll.type !== 'open feedback poll' && (
            <SmallButton onClick={handleViewAnalytics}>View Analytics</SmallButton>
          )}
          {poll.isQueued && (
            <SmallButton onClick={handleEditPoll} custom={poll.isOpen} disabled={poll.isOpen}>
              Edit
            </SmallButton>
          )}
          <SmallButton
            onClick={() => {
              setPollToBeDeleted(poll);
              setShowConfirmDeleteDialog(true);
            }}>
            Delete
          </SmallButton>
        </p>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  padding-bottom: 0.375rem;

  > div {
    background-color: ${({ isSelected, colors }) =>
      isSelected ? hexToRGB({ color: colors.tertiary, alpha: 0.2 }) : 'transparent'};
    cursor: pointer;
    font-size: 1rem;
    margin-top: 0.375em;
    padding: 0.125em 0.25em 0.25em;
    position: relative;
    transition: background-color 200ms ease-in-out;

    ${({ isSelected, isCurrentlyOpen, moderatorIsCurrentlySharingThisPollsAnalyticsWithUsers }) =>
      !isSelected &&
      (isCurrentlyOpen || moderatorIsCurrentlySharingThisPollsAnalyticsWithUsers) &&
      css`
        animation: flash 220ms linear alternate infinite;

        @keyframes flash {
          0% {
            background-color: transparent;
          }

          75% {
            background-color: ${({ colors }) => hexToRGB({ color: colors.tertiary, alpha: 0.2 })};
          }

          100% {
            background-color: ${({ colors }) => hexToRGB({ color: colors.tertiary, alpha: 0.2 })};
          }
        }
      `}

    p {
      margin-left: 1.375rem;
    }

    p:nth-of-type(1),
    p:nth-of-type(2) {
      color: ${({ isSelected, colors }) => (isSelected ? colors.tertiary : '#000')};
      line-height: 1.1em;
      margin-top: 2px !important;
      transition: color 200ms ease-in-out;
    }

    p:nth-of-type(3) {
      color: #000;
      font-size: 0.75rem;
      margin-top: 6px;
    }
  }
`;

const SelectPollCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  height: 1rem;
  margin-top: 2px;
  outline: none;
  position: absolute;
  transition: all 0.1s;
  width: 1rem;

  &:focus {
    border: 2px solid ${({ colors }) => colors.tertiary};
  }

  &:checked {
    border: 1px solid ${({ colors }) => colors.tertiary};
  }

  &:checked:before {
    color: ${({ contrast, colors }) => (contrast ? `#000` : colors.tertiary)};
    content: '✓';
    font-size: 0.8rem;
    height: 0.8rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-32%, -60%);
    width: 0.8rem;
  }
`;

const SmallButton = styled(motion.span).attrs({
  role: 'button',
  tabIndex: 0,
  whileTap: (disabled) => ({ scale: disabled ? 1 : 0.95 })
})`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  text-decoration: underline;

  &:not(:last-of-type) {
    margin-right: 1.417em;
  }
`;

export default QueuedOrClosedPoll;
