import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { hexToRGB } from 'utils';
import { AnimatePresence, motion } from 'framer-motion';
import {
  RadialCircle,
  SolidCircle1,
  SolidCircle2,
  DashedCircle,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon
} from 'assets/svgs';

function SpeakerCard({
  speaker,
  speakers,
  setOpenSpeakerCarouselAtThisIndex,
  setShowSpeakerCarouselDialog
}) {
  const speakerHasSocialLinksOrContactEmail =
    speaker.fbLink || speaker.twLink || speaker.linkedinLink || speaker.emailLink;

  return (
    <Container
      id={speaker.speakerId}
      onClick={() => {
        setOpenSpeakerCarouselAtThisIndex(speakers.findIndex((_speaker) => _speaker === speaker));
        setShowSpeakerCarouselDialog(true);
      }}>
      <InnerContainer speakerHasSocialLinksOrContactEmail={speakerHasSocialLinksOrContactEmail}>
        <Position>{speaker.position}</Position>
        <SpeakerAvatarContainer>
          <StyledRadialCircle />
          <AnimatePresence>
            <StaticAvatar image={getImage(speaker.image)} alt={speaker.name} loading="eager" />
          </AnimatePresence>
        </SpeakerAvatarContainer>
        <SpeakerName>{speaker.name}</SpeakerName>
        <SpeakerTitleAndCompany>{speaker.shortSummary}</SpeakerTitleAndCompany>
        {speakerHasSocialLinksOrContactEmail && (
          <SocialLinksAndContactEmail onClick={(e) => e.stopPropagation()}>
            {speaker.linkedinLink && (
              <SocialLink href={speaker.linkedinLink} target="_blank">
                <LinkedinIcon />
              </SocialLink>
            )}
            {speaker.twLink && (
              <SocialLink href={speaker.twLink} target="_blank">
                <TwitterIcon />
              </SocialLink>
            )}
            {speaker.fbLink && (
              <SocialLink href={speaker.fbLink} target="_blank">
                <FacebookIcon />
              </SocialLink>
            )}
            {speaker.emailLink && (
              <ContactEmail href={speaker.emailLink} target="_blank">
                <EmailIcon />
              </ContactEmail>
            )}
          </SocialLinksAndContactEmail>
        )}
        <ReadOverview>Read Bio</ReadOverview>
      </InnerContainer>
    </Container>
  );
}

const StyledRadialCircle = styled(RadialCircle)`
  left: -0.75rem;
  top: 0;
  width: 105%;
`;

const SpeakerAvatarContainer = styled.div`
  align-items: center;
  display: flex;
  height: 16.5rem;
  justify-content: center;
  margin-bottom: 1.325rem;
  position: relative;
  width: 16.5rem;

  > svg:not(.loading-spinner) {
    position: absolute;
    transition: all 0.2s ease-in-out;
  }
`;

const StaticAvatar = styled(GatsbyImage)`
  border-radius: 50%;
  width: 100%;
`;

const SpeakerName = styled.h3`
  font-size: 1.5rem;
  line-height: 1.333em;
  margin-top: 0.5rem;
`;

const SpeakerTitleAndCompany = styled.p`
  font-size: 1rem;
  line-height: 1.5em;
  margin-bottom: 0.55em;
  text-align: center;
`;

const PresentationTitle = styled.p`
  font-size: 1.25rem;
  line-height: 1.4em;
  margin-top: 0.275em;
  text-align: center;
`;

const SocialLinksAndContactEmail = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const ReadOverview = styled.h4`
  font-size: 1rem;
  line-height: 1.2em;
  margin-top: 1.55em;
  text-transform: uppercase;
`;

const Position = styled.h4`
  font-size: 1.25rem;
  line-height: 1.2em;
  margin-bottom: 1.55em;
  text-align: center;
`;

const Container = styled(motion.li)`
  cursor: pointer;
  flex: 1;
  font-family: 'Proxima Nova', sans-serif;
  height: initial;
  margin-bottom: 1.5rem;
  max-width: 20.75rem;
  position: relative;
  transform: ${({ transform }) => transform};
  transition: ${({ transition }) =>
    transition
      ? `background-color 0.2s ease-in-out, outline 0.2s ease-in-out, ${transition}`
      : 'background-color 0.2s ease-in-out, outline 0.2s ease-in-out'};

  ${({ theme }) => css`
    ${SpeakerName},
    ${SpeakerTitleAndCompany},
          ${PresentationTitle} {
      color: ${theme.primary};
      transition: color 0.2s ease-in-out;
    }

    ${ReadOverview} {
      color: ${theme.tertiary};
    }

    &:hover {
      ${StaticAvatar} {
        box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.25);
      }

      ${SpeakerAvatarContainer} {
        ${StyledRadialCircle} {
          left: -1.45rem;
          top: 0.5rem;
        }
      }
    }
  `}
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;

  > * {
    user-select: none;
  }
`;

const SocialLink = styled.a`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0 0.375rem;

  svg {
    border-radius: 50%;
    height: 1.5rem;
    transition: box-shadow 0.2s ease-in-out;
    width: 1.5rem;

    circle {
      fill: ${({ theme }) => (theme.className === 'contrast' ? theme.primary : '#C4C4C4')};
      transition: fill 0.2s ease-in-out;
    }

    path {
      fill: ${({ theme }) => (theme.className === 'contrast' ? '#000' : '#fff')};
      transition: 0.5s;
    }
  }

  &:hover svg {
    box-shadow: 0 0.188rem 0.5rem rgba(0, 0, 0, 0.25);

    circle {
      fill: ${({ theme }) => theme.secondary};
    }
  }

  &:active svg {
    transform: scale(0.9);
  }
`;

const ContactEmail = styled(SocialLink)``;

export default SpeakerCard;
