import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { hexToRGB } from 'utils';
import { LoadingSpinner } from 'components';
import { FirebaseContext, LocalContext } from 'context';
import { fadeInAndOutVariants } from 'styles';

function OpenEndedFeedbackModal({ poll, colors, eventId }) {
  const { firebase, user } = useContext(FirebaseContext);
  const [userHasAlreadyAnsweredThisPoll, setUserHasAlreadyAnsweredThisPoll] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [pollStatus, setPollStatus] = useState('');
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(null);

  useEffect(() => {
    if (firebase && user) {
      firebase.interaction.polls
        .checkIfUserHasAlreadyAnsweredThisPoll({
          uid: user.uid,
          eventId,
          pollId: poll.pollId
        })
        .then((doc) => {
          if (doc.data()) {
            setUserHasAlreadyAnsweredThisPoll(true);
            const { message } = doc.data();
            setFeedbackMessage(message);
          } else {
            setUserHasAlreadyAnsweredThisPoll(false);
            setFeedbackMessage('');
            setPollStatus('');
          }
        });
    }
  }, [firebase, user, poll, pollStatus, eventId]);

  useEffect(
    () => setSubmitButtonIsDisabled(userHasAlreadyAnsweredThisPoll || pollStatus === 'submitted'),
    [userHasAlreadyAnsweredThisPoll, poll, pollStatus]
  );

  const handleTextAreaChange = (e) => {
    e.persist();
    if (userHasAlreadyAnsweredThisPoll) return;
    setFeedbackMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userHasAlreadyAnsweredThisPoll) return;
    if (feedbackMessage.length < 1) return;

    if (pollStatus !== 'submitted' && feedbackMessage.length > 1) {
      try {
        setPollStatus('submitting');
        await firebase.interaction.polls.submitEventFeedback({
          eventId,
          pollId: poll.pollId,
          uid: user.uid,
          name: user.name,
          email: user.email,
          question: poll.question.text,
          message: feedbackMessage
        });
        setPollStatus('submitted');
      } catch (error) {
        console.error(error);
        setPollStatus('error');
      }
    }
  };

  return (
    <Underlay data-underlay colors={colors}>
      <Modal colors={colors}>
        <Question colors={colors}>
          <p>{poll.question.text}</p>
        </Question>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Option colors={colors}>
            <textarea
              rows="4"
              maxLength="146"
              disabled={userHasAlreadyAnsweredThisPoll}
              value={feedbackMessage}
              onChange={handleTextAreaChange}
            />
          </Option>
          <SubmitButton
            colors={colors}
            disabled={submitButtonIsDisabled}
            pollStatus={pollStatus}
            style={{ margin: '0' }}
            type="submit">
            {pollStatus === 'submitting' ? (
              <LoadingSpinner style={{ width: '1.625rem', color: '#fff' }} />
            ) : pollStatus === 'submitted' || userHasAlreadyAnsweredThisPoll ? (
              'Submitted'
            ) : (
              'Submit'
            )}
          </SubmitButton>
        </form>
      </Modal>
    </Underlay>
  );
}

const Underlay = styled(motion.div).attrs(fadeInAndOutVariants())`
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.6);
  color: #000;
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 1.625rem 1.625rem;
  position: absolute;
  top: 0;
  width: 100%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.31rem grey;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ colors }) => colors.tertiary};
    border-radius: 0.625rem;
  }
`;

const Modal = styled.div`
  background-color: #fff;
  margin: 2rem auto 0;
  max-width: 23.75rem;
  position: relative;
  form {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.875rem;
    > div {
      button {
        margin-left: auto;
        margin-top: 2rem;
      }
    }
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.tertiary, alpha: 0.2 })};
  color: ${({ colors }) => colors.tertiary};
  padding: 0.75rem 1.5rem;
  width: 100%;
  p {
    font-weight: 700;
  }
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  p {
    color: #000;
    font-size: 0.875rem;
    font-weight: 300;
    padding: 0.5rem 0;
  }
  textarea {
    border: 1px solid ${({ colors }) => colors.tertiary};
    border-radius: 2px;
    color: #4f4f4f;
    font-family: 'Proxima Nova', sans-serif;
    font-size: 0.825rem;
    font-weight: 300;
    outline: none;
    padding: 0.825rem !important;
    resize: none;
    width: 100%;
  }
  > * {
    &:last-child {
      padding: 0;
    }
  }
`;

const SubmitButton = styled(motion.button).attrs(({ disabled }) => ({
  whileTap: {
    scale: disabled ? 1 : 0.95
  }
}))`
  align-items: center;
  align-self: flex-end;
  background-color: ${({ disabled, pollStatus, colors }) =>
    !disabled || pollStatus === 'submitting'
      ? colors.tertiary
      : pollStatus === 'submitted'
        ? colors.tertiary
        : 'rgba(196, 196, 196, 0.3)'};
  border-radius: 0.125em;
  color: ${({ disabled, pollStatus }) =>
    disabled && pollStatus !== 'submitted' ? '#c4c4c4' : '#fff'};
  cursor: ${({ disabled, pollStatus }) =>
    pollStatus === 'submitting'
      ? 'none'
      : disabled && pollStatus === 'submitted'
        ? 'default'
        : disabled && pollStatus !== 'submitted'
          ? 'not-allowed'
          : 'pointer'};
  display: flex;
  font-weight: 700;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1rem;
  height: 2.5em;
  margin-top: 1.25em;
  width: 11.875em;
`;

export default OpenEndedFeedbackModal;
