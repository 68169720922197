import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { FirebaseContext } from 'context';
import { Button } from 'components';
import { Container, Title, Card, Label } from 'components/Admin/Shared';
import { Search } from 'assets/svgs';
import FixRequiredSelect from 'components/Forms/FixRequiredSelect';

function FormSelect(props) {
  const options = props.selectAll
    ? [{ label: 'Select All', value: 'all' }, ...props.options]
    : props.options;

  return (
    <FixRequiredSelect
      {...props}
      SelectComponent={Select}
      options={options}
      isMulti={props.isMulti}
    />
  );
}

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <Search />
    </components.DropdownIndicator>
  );
}

function FormAsyncSelect(props) {
  return (
    <FixRequiredSelect
      {...props}
      SelectComponent={AsyncSelect}
      options={props.options}
      components={props.components}
    />
  );
}

const roles = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Organiser', value: 'Organiser' },
  { label: 'Moderator', value: 'Moderator' },
  { label: 'Speaker', value: 'Speaker' },
  { label: 'Editor', value: 'Editor' }
];

function RoleManagement({ events, customDropdownMenuStyles }) {
  const { firebase } = useContext(FirebaseContext);
  const [userUid, setUserUid] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [formIncomplete, setFormIncomplete] = useState(true);
  const [feedback, setFeedback] = useState('');
  const [grantingPermissions, setGrantingPermissons] = useState(false);
  const [removingPermissions, setRemovingPermissons] = useState(false);

  const clearFields = () => {
    setSelectedUser(null);
    setSelectedEvents([]);
    setSelectedRole(null);
    setFeedback('');
  };

  const searchAllUsersInAlgolia = async (inputValue) => {
    const _searchResults = [];
    await firebase.algolia.search({ indexType: 'users', tag: inputValue }).then((res) => {
      if (res.data.length > 0) {
        res.data.forEach((hit) => {
          _searchResults.push({
            label: `${hit.name} - ${hit.email}`,
            value: `${hit.uid}`
          });
        });
      }
    });
    return _searchResults;
  };

  const loadOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(searchAllUsersInAlgolia(inputValue));
      }, 1000);
    });

  const handleGrantSubmit = async () => {
    setGrantingPermissons(true);
    const selectedEventIds = selectedEvents.map(({ eventId }) => eventId);
    try {
      await firebase.admin[`grant${selectedRole.value}Role`]({
        uid: userUid,
        eventIds: selectedEventIds
      });
      clearFields();
    } catch (error) {
      console.error(error);
      setFeedback(error.message || 'Something went wrong. Check console for more information.');
    } finally {
      setGrantingPermissons(false);
    }
  };

  const handleRemoveSubmit = async () => {
    setRemovingPermissons(true);
    const selectedEventIds = selectedEvents.map(({ eventId }) => eventId);
    try {
      await firebase.admin[`remove${selectedRole.value}Role`]({
        uid: selectedUser.value,
        eventIds: selectedEventIds
      });
      clearFields();
    } catch (error) {
      console.error(error);
      setFeedback(error.message || 'Something went wrong. Check console for more information.');
    } finally {
      setRemovingPermissons(false);
    }
  };

  useEffect(() => {
    if (userUid.length > 5 && selectedRole && selectedEvents.length > 0) {
      setFormIncomplete(false);
    } else {
      setFormIncomplete(true);
    }
  }, [selectedUser, selectedEvents, selectedRole]);

  return (
    <Container>
      <Title>Role Management</Title>
      <Card>
        <div>
          <Label>User</Label>
          {/* <FormAsyncSelect
            value={selectedUser}
            placeholder="Search user name or email address"
            components={{ DropdownIndicator }}
            styles={customDropdownMenuStyles()}
            onChange={setSelectedUser}
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
          /> */}
          <FormInput
            id="uid"
            name="uid"
            type="uid"
            placeholder="UID"
            onChange={(e) => setUserUid(e.target.value)}
            value={userUid}
          />
        </div>
        {selectedRole?.label !== 'Admin' && (
          <div>
            <Label>Event</Label>
            <FormSelect
              isMulti
              selectAll
              value={selectedEvents}
              onChange={(selected) =>
                selected.length && selected.find((option) => option.value === 'all')
                  ? setSelectedEvents(events)
                  : setSelectedEvents(selected)
              }
              placeholder="Select"
              styles={customDropdownMenuStyles({ isMulti: true })}
              options={events}
            />
          </div>
        )}
        <div>
          <Label>Role</Label>
          <FormSelect
            value={selectedRole}
            onChange={setSelectedRole}
            placeholder="Select"
            styles={customDropdownMenuStyles()}
            options={roles}
          />
        </div>
        <AnimatePresence>
          {selectedRole && (
            <AnimatePresence>
              <Buttons>
                <Button
                  color="primary"
                  disabled={formIncomplete}
                  onClick={() => handleGrantSubmit()}
                  loading={grantingPermissions}
                  loadingButton>
                  {`Grant Permission${selectedEvents.length > 1 ? 's' : ''}`}
                </Button>
                {selectedRole.label !== 'Admin' && (
                  <Button
                    color="warning"
                    disabled={formIncomplete}
                    onClick={() => handleRemoveSubmit()}
                    loading={removingPermissions}
                    loadingButton>
                    {`Remove Permission${selectedEvents.length > 1 ? 's' : ''}`}
                  </Button>
                )}
              </Buttons>
            </AnimatePresence>
          )}
          {feedback && <Feedback>{feedback}</Feedback>}
        </AnimatePresence>
      </Card>
    </Container>
  );
}

const Feedback = styled.span`
  color: #f55151;
  font-size: 0.875rem;
`;

const Buttons = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const FormInput = styled.input`
  align-items: center;
  background: transparent;
  border: 1px solid #c4c4c4;
  color: #1752a0;
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-top: 12px;
  min-height: 40px;
  outline: none;
  padding-left: 1.25rem;
  width: 100%;

  :focus {
    border: 1px solid #c4c4c4;
  }
  ::placeholder {
    color: #1752a0;
    font-size: 1rem;
    font-style: italic;
    font-weight: 300;
    opacity: 0.65;
  }
`;

export default RoleManagement;
