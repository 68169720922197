import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  increment as firestoreIncrement
} from 'firebase/firestore';

function ChatFactory(firebase) {
  const submitEventComment = async ({
    eventId,
    avatarUrl,
    name,
    text,
    profession,
    company,
    socials,
    uid,
    tags
  }) =>
    addDoc(collection(firebase.fsdb, 'events', eventId, 'comments'), {
      avatarUrl,
      name,
      text,
      timestamp: serverTimestamp(),
      profession,
      company,
      socials,
      uid,
      pinned: {
        status: false,
        timestamp: 0
      },
      likes: {
        total: 0,
        likedBy: []
      },
      tags
    });

  const editEventComment = async ({ eventId, commentId, updatedText, updatedTags }) =>
    updateDoc(doc(firebase.fsdb, 'events', eventId, 'comments', commentId), {
      text: updatedText,
      tags: updatedTags
    });

  const deleteEventComment = async ({ eventId, commentId }) =>
    deleteDoc(doc(firebase.fsdb, 'events', eventId, 'comments', commentId));

  const likeEventComment = async ({ eventId, commentId, uid }) =>
    Promise.all([
      updateDoc(doc(firebase.fsdb, 'events', eventId, 'comments', commentId), {
        'likes.total': firestoreIncrement(1),
        'likes.likedBy': arrayUnion(uid)
      })
    ]);

  const pinEventComment = async ({ eventId, commentId }) =>
    updateDoc(doc(firebase.fsdb, 'events', eventId, 'comments', commentId), {
      pinned: {
        status: true,
        timestamp: serverTimestamp()
      }
    });

  const unpinEventComment = async ({ eventId, commentId }) =>
    updateDoc(doc(firebase.fsdb, 'events', eventId, 'comments', commentId), {
      pinned: {
        status: false,
        timestamp: 0
      }
    });

  const subscribeToEventComments = ({ eventId, snapshot }) =>
    onSnapshot(
      query(
        collection(firebase.fsdb, 'events', eventId, 'comments'),
        orderBy('pinned.status', 'desc'),
        orderBy('pinned.timestamp', 'asc'),
        orderBy('timestamp', 'desc'),
        limit(100)
      ),
      snapshot
    );

  return {
    submitEventComment,
    editEventComment,
    deleteEventComment,
    likeEventComment,
    pinEventComment,
    unpinEventComment,
    subscribeToEventComments
  };
}

export default ChatFactory;
