import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Button } from 'components';
import { Container, Title, Card, Label, Options } from 'components/Admin/Shared';
import { FirebaseContext } from 'context';

function DummyUsers({ events }) {
  const [amountOfDummyUsers, setAmountOfDummyUsers] = useState(1);
  const [addingDummyUsers, setAddingDummyUsers] = useState(false);
  const [deletingDummyUsers, setDeletingDummyUsers] = useState(false);
  const { firebase } = useContext(FirebaseContext);

  const handleInputChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (event.target.value === '') {
      setAmountOfDummyUsers(0);
    } else if (value < 1000) {
      setAmountOfDummyUsers(value);
    }
  };

  const handleAddDummyUsers = async () => {
    if (amountOfDummyUsers) {
      try {
        setAddingDummyUsers(true);
        await firebase.admin.addDummyUsers({
          amountOfDummyUsers,
          eventIds: events.map(({ eventId }) => eventId)
        });
      } catch (error) {
        console.error(error);
      } finally {
        setAddingDummyUsers(false);
      }
    }
  };

  const handleDeleteDummyUsers = async () => {
    try {
      setDeletingDummyUsers(true);
      await firebase.admin.deleteAllDummyUsers();
    } catch (error) {
      console.error(error);
    } finally {
      setDeletingDummyUsers(false);
    }
  };

  return (
    <Container>
      <Title>Dummy Users</Title>
      <Card>
        <div>
          <Label>Number of Dummies</Label>
          <Field>
            <DecrementSpinner
              disabled={amountOfDummyUsers <= 1}
              onClick={() => setAmountOfDummyUsers(amountOfDummyUsers - 1)}
            />
            <Input
              type="number"
              id="amountOfDummyUsers"
              name="amountOfDummyUsers"
              min="1"
              max="999"
              value={amountOfDummyUsers === 0 ? '' : amountOfDummyUsers}
              onChange={handleInputChange}
            />
            <IncrementSpinner
              disabled={amountOfDummyUsers >= 999}
              onClick={() => setAmountOfDummyUsers(amountOfDummyUsers + 1)}
            />
          </Field>
        </div>
        <Options>
          <Button
            color="primary"
            onClick={handleAddDummyUsers}
            loading={addingDummyUsers}
            loadingButton>
            Add Dummy Users
          </Button>
          <Button
            color="warning"
            onClick={handleDeleteDummyUsers}
            loading={deletingDummyUsers}
            loadingButton>
            Delete All Dummies
          </Button>
        </Options>
      </Card>
    </Container>
  );
}

const Field = styled.div`
  align-items: center;
  display: flex;
  flex-flow: nowrap;
  gap: 1rem;
  margin-top: 12px;
`;

const Input = styled.input.attrs({
  type: 'number'
})`
  border: 1px solid #c4c4c4;
  color: #004d9a;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  height: 40px;
  outline: none;
  padding: 0 0.75rem;
  text-align: center;
  appearance: textfield;
  max-width: 68px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  &:focus {
    border: 1.5px solid #c4c4c4;
  }
  &::placeholder {
    color: #004d9a;
    font-style: italic;
    font-weight: 300;
  }
`;

const Spinner = styled(motion.button).attrs({
  whileTap: {
    scale: 0.95
  }
})`
  background: transparent;
  border: ${({ disabled }) => (disabled ? '2px solid #c4c4c4' : '2px solid #004d9a')};
  border-radius: 50%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  height: 1rem;
  outline: none;
  position: relative;
  width: 1rem;
  &:focus {
    border: 2px solid #004d9a;
  }
  &:before {
    align-items: center;
    border-radius: 50%;
    color: ${({ disabled }) => (disabled ? '#c4c4c4' : ' #004d9a')};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const IncrementSpinner = styled(Spinner)`
  &:before {
    content: '+';
    font-size: 1.125rem;
    padding-top: 0.0325rem;
  }
`;

const DecrementSpinner = styled(Spinner)`
  &:before {
    content: '-';
    font-size: 1.5rem;
    padding-bottom: 0.25rem;
  }
`;

export default DummyUsers;
