import React from 'react';
import styled from 'styled-components';
import { hexToRGB } from 'utils';
import { Button } from 'components';

function ConfirmHCP({ closeConfirmHCPDialog, colors }) {
  return (
    <Container colors={colors}>
      <Question colors={colors}>
        <p>
          To continue viewing this page, you confirm that you are a Healthcare Professional in
          Republic of Ireland.
        </p>
      </Question>
      <Button
        color="secondary"
        focusRipple={false}
        onClick={closeConfirmHCPDialog}
        style={{ height: '2.5rem' }}>
        I Confirm
      </Button>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 28rem;
  position: relative;

  h4 {
    color: #4f4f4f;
    font-size: 1rem;
    font-weight: 400;
    padding: 1.75rem 1.5rem 1.25rem 1.5rem;
    text-align: center;
  }

  button {
    &:nth-of-type(1) {
      border-radius: 2px;
      margin: 0.9rem;
    }
  }
`;

const Question = styled.div`
  background-color: ${({ colors }) => hexToRGB({ color: colors.secondary, alpha: 0.2 })};
  color: ${({ colors }) => colors.secondary};
  padding: 0.75rem 1.5rem;
  text-align: center;
  width: 100%;

  p {
    font-size: 1.4rem;
    font-weight: 700;
  }
`;

export default ConfirmHCP;
