import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { EmojiClap, EmojiLike, EmojiLove, EmojiSmile } from 'assets/svgs';
import { fadeInAndOutVariants } from 'styles';
import { FirebaseContext } from 'context';

function EmojiReactions({ eventId, showEmojisSelector, setShowEmojisSelector }) {
  const { firebase } = useContext(FirebaseContext);
  const handleEmojiClick = ({ emojiType }) =>
    firebase.interaction.emojis.sendEmoji({ eventId, emojiType }).catch(console.error);
  const handleEmojiKeyDown = ({ e, emojiType }) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleEmojiClick({ emojiType });
    } else if (e.key === 'Escape' && showEmojisSelector) {
      setShowEmojisSelector(false);
    }
  };
  return (
    <EmojiSelector
      variants={fadeInAndOutVariants({
        exitDelay: 0.1
      })}
      initial="initial"
      animate="animate"
      exit="exit">
      <div>
        <Emoji
          tabIndex={0}
          onClick={() => handleEmojiClick({ emojiType: 'smile' })}
          onKeyDown={(e) => handleEmojiKeyDown({ e, emojiType: 'smile' })}>
          <EmojiSmile />
        </Emoji>
        <Emoji
          tabIndex={0}
          onClick={() => handleEmojiClick({ emojiType: 'love' })}
          onKeyDown={(e) => handleEmojiKeyDown({ e, emojiType: 'love' })}>
          <EmojiLove />
        </Emoji>
        <Emoji
          tabIndex={0}
          onClick={() => handleEmojiClick({ emojiType: 'like' })}
          onKeyDown={(e) => handleEmojiKeyDown({ e, emojiType: 'like' })}>
          <EmojiLike />
        </Emoji>
        <Emoji
          tabIndex={0}
          onClick={() => handleEmojiClick({ emojiType: 'clap' })}
          onKeyDown={(e) => handleEmojiKeyDown({ e, emojiType: 'clap' })}>
          <EmojiClap />
        </Emoji>
        <div />
      </div>
    </EmojiSelector>
  );
}

const EmojiSelector = styled(motion.div)`
  cursor: pointer;
  height: 3.25rem;
  position: absolute;
  right: -0.063rem;
  top: -3.125rem;
  width: 10rem;
  > div {
    background-color: #fff;
    box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.14);
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
    padding: 0.5rem 0.688rem;
    position: relative;
  }
`;

const Emoji = styled(motion.span).attrs({
  whileTap: {
    scale: 0.9
  },
  whileHover: {
    scale: 1.1
  }
})`
  display: inline-flex;

  svg {
    width: 1.5rem;
  }
`;

export default EmojiReactions;
