const firebaseConfig = {
  apiKey: 'AIzaSyBBXTzyJzNFErwwcj4haGymOpveEF3n1PI',
  authDomain: 'daiichi-medical-education.firebaseapp.com',
  databaseURL: 'https://daiichi-medical-education-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'daiichi-medical-education',
  storageBucket: 'daiichi-medical-education.appspot.com',
  messagingSenderId: '605892849616',
  appId: '1:605892849616:web:8f3df4b2d4248be05613d5',
  measurementId: 'G-BZ71RZW1DL'
};

export default firebaseConfig;
