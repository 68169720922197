import * as React from 'react';

function SvgRadialCircle(props) {
  return (
    <svg viewBox="0 0 357 357" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M178.5 357c98.583 0 178.5-79.917 178.5-178.5S277.083 0 178.5 0 0 79.917 0 178.5 79.917 357 178.5 357Z"
        fill="url(#RadialCircle_svg__a)"
      />
      <defs>
        <radialGradient
          id="RadialCircle_svg__a"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(90 0 178.5) scale(178.5)">
          <stop offset={0.885} stopColor="#FFA74A" />
          <stop offset={1} stopColor="#F01561" />
        </radialGradient>
      </defs>
    </svg>
  );
}
export default SvgRadialCircle;
