import { getAuth } from 'firebase/auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

let firebaseInstance;

function FirebaseFactory(app) {
  const auth = getAuth(app);

  const rtdb = getDatabase(app);

  const fsdb = getFirestore(app);

  const storage = getStorage(app);

  const functions = getFunctions(app, 'europe-west3');

  if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectDatabaseEmulator(rtdb, 'localhost', 9000);
  }

  const subscribeToUserUpdates = ({ uid, snapshot }) =>
    onSnapshot(doc(fsdb, 'users', uid), snapshot);

  const subscribeToEventUpdates = ({ eventId, snapshot }) =>
    onSnapshot(doc(fsdb, 'events', eventId), snapshot);

  return {
    auth,
    rtdb,
    fsdb,
    storage,
    functions,
    subscribeToUserUpdates,
    subscribeToEventUpdates
  };
}

function getFirebaseInstance(app) {
  if (!firebaseInstance && app) {
    firebaseInstance = FirebaseFactory(app);
    return firebaseInstance;
  }

  if (firebaseInstance) {
    return firebaseInstance;
  }

  return null;
}

export default getFirebaseInstance;
