/* eslint-disable func-names */
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ThemeProvider, createTheme, alpha } from '@mui/material/styles';
import { defaultColors } from 'styles';

const defaultStyle = {
  borderRadius: '1.625em',
  fontSize: '1rem',
  fontWeight: '700',
  minWidth: '7.5em',
  padding: '0.625em 1.625em',
  position: 'relative',
  overflow: 'hidden'
};

const MaterialUIButton = forwardRef((props, ref) => {
  const {
    children,
    className,
    color = 'tertiary',
    disabled = false,
    changeColorWhenDisabled = true,
    focusRipple = true,
    htmlFor,
    loading,
    loadingButton,
    loadingSpinnerSize = '1.625rem',
    loadingSpinnerColor = '#ffffff',
    onClick,
    onKeyDown,
    onTouchEnd,
    onMouseEnter,
    onMouseLeave,
    disableRipple,
    shine,
    shineDuration = 1,
    size = 'medium',
    startIcon,
    style = {},
    type = 'button',
    variant = 'contained'
  } = props;

  const theme = createTheme({
    palette: {
      primary: {
        main: defaultColors.primary,
        light: alpha(defaultColors.primary, 0.5),
        dark: alpha(defaultColors.primary, 0.9),
        contrastText: '#ffffff'
      },
      secondary: {
        main: defaultColors.secondary,
        light: alpha(defaultColors.secondary, 0.5),
        dark: alpha(defaultColors.secondary, 0.9),
        contrastText: '#ffffff'
      },
      tertiary: {
        main: defaultColors.tertiary,
        light: alpha(defaultColors.tertiary, 0.5),
        dark: alpha(defaultColors.tertiary, 0.9),
        contrastText: '#ffffff'
      },
      quaternary: {
        main: defaultColors.quaternary,
        light: alpha(defaultColors.quaternary, 0.5),
        dark: alpha(defaultColors.quaternary, 0.9),
        contrastText: '#ffffff'
      },
      warning: {
        main: defaultColors.warning,
        light: alpha(defaultColors.warning, 0.5),
        dark: alpha(defaultColors.warning, 0.9),
        contrastText: '#ffffff'
      },
      action: {
        disabledBackground: changeColorWhenDisabled ? '#ededed' : '',
        disabled: changeColorWhenDisabled ? '#c4c4c4' : '#fff'
      },
      none: {
        main: 'transparent',
        light: 'transparent',
        dark: 'transparent',
        contrastText: defaultColors.tertiary
      }
    }
  });

  return (
    <ThemeProvider theme={theme} disabled={disabled}>
      {loadingButton ? (
        <LoadingButton
          className={className}
          loadingIndicator={
            <CircularProgress style={{ color: loadingSpinnerColor }} size={loadingSpinnerSize} />
          }
          color={color}
          disableElevation
          disabled={disabled}
          changeColorWhenDisabled={changeColorWhenDisabled}
          loading={loading}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onTouchEnd={onTouchEnd}
          onKeyDown={onKeyDown}
          disableRipple={disableRipple}
          focusRipple={focusRipple}
          htmlFor={htmlFor}
          ref={ref}
          size={size}
          sx={{
            ...defaultStyle,
            ...style
          }}
          type={type}
          variant={variant}>
          {children}
          {shine && !loading && <Shine shineDuration={shineDuration} />}
        </LoadingButton>
      ) : (
        <Button
          className={className}
          color={color}
          disableElevation
          disabled={disabled}
          changeColorWhenDisabled={changeColorWhenDisabled}
          htmlFor={htmlFor}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onTouchEnd={onTouchEnd}
          onKeyDown={onKeyDown}
          disableRipple={disableRipple}
          focusRipple={focusRipple}
          ref={ref}
          size={size}
          startIcon={startIcon}
          sx={{
            ...defaultStyle,
            ...style
          }}
          type={type}
          variant={variant}>
          {children}
          {shine && <Shine shineDuration={shineDuration} />}
        </Button>
      )}
    </ThemeProvider>
  );
});

export const Shine = styled.div`
  animation: shineAnimation ${({ shineDuration }) => shineDuration}s infinite;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 25%,
    rgba(128, 186, 232, 0) 49%,
    rgba(125, 185, 232, 0) 50%,
    rgba(125, 185, 232, 0) 100%
  );
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  width: 100%;
  z-index: 1;

  @keyframes shineAnimation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

export default MaterialUIButton;
