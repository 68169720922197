import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Chat, Polls, ParticipantModal, Participants, QAndA } from 'components';
import { FirebaseContext } from 'context';
import { Howl } from 'howler';
import { hexToRGB } from 'utils';
import { airWhoosh } from 'assets/sound-effects';

const airWhooshHowl = new Howl({
  src: airWhoosh,
  volume: 0.5
});

function Interaction({
  colors,
  eventId,
  forcedActiveTab,
  showOnlinePresenceToUsers,
  questionCurrentlyBeingAnsweredLive,
  isChatEnabled,
  isQAndAEnabled,
  isPollsEnabled,
  isParticipantsEnabled,
  activeTab,
  setActiveTab
}) {
  const { firebase, user } = useContext(FirebaseContext);
  const [submittedQuestions, setSubmittedQuestions] = useState([]);
  const [amountOfUnseenQuestions, setAmountOfUnseenQuestions] = useState([]);
  const [participantModalData, setParticipantModalData] = useState(false);
  const [chatTextInputValue, setChatTextInputValue] = useState('');
  const [currentlyOpenPoll, setCurrentlyOpenPoll] = useState(null);
  const [chatPreviousTextInputValue, setChatPreviousTextInputValue] = useState('');
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [editingComment, setEditingComment] = useState(false);
  const [idOfCommentCurrentlyBeingEdited, setIdOfCommentCurrentlyBeingEdited] = useState(null);
  const [textOfCommentBeforeBeingEdited, setTextOfCommentBeforeBeingEdited] = useState('');
  const [questionTextArea, setQuestionTextArea] = useState('');
  const [submitAnonymousQuestion, setSubmitAnonymousQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [editQuestion, setEditQuestion] = useState({
    edit: false,
    question: {
      qid: '',
      uid: '',
      text: ''
    }
  });

  useEffect(() => {
    let unsubscribeFromSubmittedQuestions;

    if (firebase && user) {
      if (user) {
        unsubscribeFromSubmittedQuestions =
          firebase.interaction.qAndA.subscribeToSubmittedQuestions({
            eventId,
            uid: user.uid,
            isModerator: user?.isModerator?.includes(eventId),
            snapshot: (snapshot) => {
              if (!snapshot.empty) {
                const _submittedQuestions = [];
                snapshot.forEach((doc) => {
                  _submittedQuestions.push({
                    qid: doc.id,
                    ...doc.data()
                  });
                });

                setSubmittedQuestions(_submittedQuestions);
              } else {
                setSubmittedQuestions([]);
              }
            }
          });
        return () => {
          if (unsubscribeFromSubmittedQuestions) {
            unsubscribeFromSubmittedQuestions();
          }
        };
      }
    }
  }, [firebase, user, eventId]);

  useEffect(() => {
    if (submittedQuestions) {
      const _amountOfUnseenQuestions = submittedQuestions.filter(
        (question) => question.seen.status === false
      ).length;
      if (activeTab !== 'Q&A' || _amountOfUnseenQuestions !== 1) {
        setAmountOfUnseenQuestions(_amountOfUnseenQuestions);
      }
    }
  }, [submittedQuestions, activeTab]);

  useEffect(() => {
    if (
      user &&
      (!user.isModerator || !user?.isModerator?.includes(eventId)) &&
      forcedActiveTab &&
      activeTab !== forcedActiveTab
    ) {
      setActiveTab(forcedActiveTab);
      if (forcedActiveTab === 'Polls') {
        airWhooshHowl.play();
      }
    }
    return () => {
      if (user?.isModerator?.includes(eventId) && forcedActiveTab) {
        firebase.interaction.forceActiveTab({ eventId, tabName: null });
      }
    };
  }, [user, forcedActiveTab, eventId]);

  useEffect(() => {
    if (participantModalData) {
      setParticipantModalData(false);
    }
  }, [activeTab, forcedActiveTab]);

  const handleParticipantAvatarOrNameClick = (participant) => {
    if (participant) {
      setParticipantModalData({
        avatarUrl: participant.avatarUrl,
        name: participant.name,
        profession: participant.profession,
        company: participant.company,
        socials: participant.socials,
        uid: participant.uid
      });
    } else {
      setParticipantModalData(false);
    }
  };

  return (
    <Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Tabs>
        {isChatEnabled && (
          <Tab isActive={activeTab === 'Chat'} onClick={() => setActiveTab('Chat')} colors={colors}>
            Chat
          </Tab>
        )}
        {isQAndAEnabled && (
          <Tab
            isActive={activeTab === 'Q&A'}
            showNotificationIcon={amountOfUnseenQuestions > 0}
            isModerator={user?.isModerator?.includes(eventId)}
            onClick={() => setActiveTab('Q&A')}
            colors={colors}>
            {user?.isModerator?.includes(eventId) && amountOfUnseenQuestions > 0 && (
              <Notification>
                {amountOfUnseenQuestions > 99 ? '99+' : amountOfUnseenQuestions}
              </Notification>
            )}
            Q & A
          </Tab>
        )}
        {isPollsEnabled && (
          <Tab
            isActive={activeTab === 'Polls'}
            onClick={() => setActiveTab('Polls')}
            colors={colors}>
            Polls
          </Tab>
        )}
        {isParticipantsEnabled && (
          <Tab
            isActive={activeTab === 'Participants'}
            onClick={() => setActiveTab('Participants')}
            colors={colors}>
            Participants
          </Tab>
        )}
      </Tabs>
      <Modules>
        {isChatEnabled && (
          <AnimatePresence>
            {activeTab === 'Chat' && (
              <Chat
                colors={colors}
                eventId={eventId}
                handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
                chatTextInputValue={chatTextInputValue}
                setChatTextInputValue={setChatTextInputValue}
                chatPreviousTextInputValue={chatPreviousTextInputValue}
                setChatPreviousTextInputValue={setChatPreviousTextInputValue}
                editingComment={editingComment}
                setEditingComment={setEditingComment}
                idOfCommentCurrentlyBeingEdited={idOfCommentCurrentlyBeingEdited}
                setIdOfCommentCurrentlyBeingEdited={setIdOfCommentCurrentlyBeingEdited}
                textOfCommentBeforeBeingEdited={textOfCommentBeforeBeingEdited}
                setTextOfCommentBeforeBeingEdited={setTextOfCommentBeforeBeingEdited}
                taggedUsers={taggedUsers}
                setTaggedUsers={setTaggedUsers}
              />
            )}
          </AnimatePresence>
        )}
        {isQAndAEnabled && (
          <AnimatePresence>
            {activeTab === 'Q&A' && (
              <QAndA
                colors={colors}
                eventId={eventId}
                questionCurrentlyBeingAnsweredLive={questionCurrentlyBeingAnsweredLive}
                submittedQuestions={submittedQuestions}
                textArea={questionTextArea}
                setTextArea={setQuestionTextArea}
                submitAnonymousQuestion={submitAnonymousQuestion}
                setSubmitAnonymousQuestion={setSubmitAnonymousQuestion}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                editQuestion={editQuestion}
                setEditQuestion={setEditQuestion}
              />
            )}
          </AnimatePresence>
        )}
        {isPollsEnabled && (
          <AnimatePresence>
            {activeTab === 'Polls' && (
              <Polls
                colors={colors}
                eventId={eventId}
                currentlyOpenPoll={currentlyOpenPoll}
                setCurrentlyOpenPoll={setCurrentlyOpenPoll}
              />
            )}
          </AnimatePresence>
        )}
        {isParticipantsEnabled && (
          <>
            <AnimatePresence>
              {activeTab === 'Participants' && (
                <Participants
                  colors={colors}
                  eventId={eventId}
                  showOnlinePresenceToUsers={showOnlinePresenceToUsers}
                  handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
              {participantModalData && (
                <ParticipantModal
                  colors={colors}
                  handleParticipantAvatarOrNameClick={handleParticipantAvatarOrNameClick}
                  participant={participantModalData}
                  eventId={eventId}
                />
              )}
            </AnimatePresence>
          </>
        )}
      </Modules>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-self: center;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1150px) {
    justify-self: unset;
    height: 100%;
  }
`;

const Modules = styled.div`
  background-color: #fff;
  border: 1px solid #bdbdbd;
  border-top: none;
  height: 100%;
  margin-top: 2.875rem;
  position: relative;
  width: 100%;

  > div {
    height: 100%;
    position: absolute;
    width: 100%;
  }
`;

const Tabs = styled.div`
  align-items: flex-end;
  background-color: transparent;
  display: flex;
  height: 2.875rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Tab = styled.div`
  align-items: center;
  border: 0.071em solid #bdbdbd;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  padding: 0 1.125em;
  position: relative;
  text-transform: uppercase;
  transition: all 200ms ease-in-out;

  &:first-child {
    border-right: none;
  }

  &:last-child {
    border-left: none;
  }

  ${({ isActive, isModerator, showNotificationIcon }) =>
    isActive
      ? css`
          background-color: #fff;
          border-top-color: ${({ colors, theme }) =>
            theme.className === 'contrast' ? theme.secondary : colors.tertiary};
          border-top-width: 0.357em;
          color: #3c3c3c;
          height: 3.286em;
          width: 51%;
        `
      : isModerator && showNotificationIcon
        ? css`
            background-color: ${({ colors }) => hexToRGB({ color: colors.tertiary, alpha: 0.3 })};
            border: 0.071em solid ${({ colors }) => colors.tertiary};
            color: #bdbdbd;
            height: 2.929em;
            width: 49%;
          `
        : css`
            background-color: #e5e5e5;
            border-top-color: #bdbdbd;
            border-top-width: 0.071em;
            color: #bdbdbd;
            height: 2.929em;
            width: 49%;
          `}
`;

const Notification = styled.div`
  align-self: center;
  background: #f55151;
  border-radius: 1rem;
  color: white;
  display: flex;
  font-size: 0.5rem;
  justify-content: center;
  line-height: 1rem;
  padding: 0 6px;
  position: absolute;
  right: 10%;
  top: -0.5rem;
`;

export default Interaction;
