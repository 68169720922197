import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

function SvgMaximise(props) {
  const [active, setActive] = useState(false);
  return (
    <StyledSVG
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onMouseDown={() => setActive(true)}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <motion.rect
        animate={{
          scale: active ? 1.0625 : 1
        }}
        x={0.5}
        y={0.5}
        width={11}
        height={11}
        rx={1.5}
        stroke="#FFA74A"
      />
      <motion.path
        animate={{
          x: active ? '5%' : 0,
          y: active ? '5%' : 0
        }}
        d="M9 6V9H6"
        stroke="#FFA74A"
      />
      <motion.path
        animate={{
          x: active ? '5%' : 0,
          y: active ? '5%' : 0
        }}
        d="M6 6L9 9"
        stroke="#FFA74A"
      />
      <motion.path
        animate={{
          x: active ? '-5%' : 0,
          y: active ? '-5%' : 0
        }}
        d="M3 6L3 3L6 3"
        stroke="#FFA74A"
      />
      <motion.path
        animate={{
          x: active ? '-5%' : 0,
          y: active ? '-5%' : 0
        }}
        d="M6 6L3 3"
        stroke="#FFA74A"
      />
    </StyledSVG>
  );
}
const StyledSVG = styled(motion.svg)`
  overflow: visible;
`;
export default SvgMaximise;
