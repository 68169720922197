import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { generateNanoId } from 'utils';
import { AnimatePresence } from 'framer-motion';
import { LocalContext, FirebaseContext } from 'context';
import { Button } from 'components';
import GroupMembers from './GroupMembers';

function GroupWatchingDialog({
  colors,
  eventId,
  question,
  setShowGroupWatchingDialog,
  groupWatchingDialogRef
}) {
  const { handleDialog } = useContext(LocalContext);
  const { user, firebase } = useContext(FirebaseContext);

  const initialGroupMembersState = () => [
    {
      id: generateNanoId(),
      name: ''
    },
    {
      id: generateNanoId(),
      name: ''
    }
  ];

  const [groupMembers, setGroupMembers] = useState(initialGroupMembersState());
  const [addGroupMembers, setAddGroupMembers] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const updatingGroupMembers = user?.groupWatching?.[eventId];

  useEffect(() => {
    if (updatingGroupMembers) {
      const _groupMembers = user.groupWatching[eventId];

      _groupMembers.push({
        id: generateNanoId(),
        name: ''
      });

      setGroupMembers(_groupMembers);
    }
  }, []);

  const handleGroupMemberTextChange = (e, memberId) => {
    const { value } = e.target;

    const allMembersIds = groupMembers.map((member) => member.id);

    const lastFieldIsFocused = memberId === allMembersIds[allMembersIds.length - 1];

    // Adds an extra member field when user starts typing in the last member field.
    if (lastFieldIsFocused && value !== '') {
      setGroupMembers((currentState) => [
        ...currentState,
        {
          id: generateNanoId(),
          name: ''
        }
      ]);
    }

    setGroupMembers((currentState) =>
      currentState.map((member) => {
        if (member.id === memberId) {
          member.name = value;
        }
        return member;
      })
    );
  };

  const closeGroupWatchingDialog = () => {
    setMessage('Thank You');
    setTimeout(
      () =>
        handleDialog({
          dialogRef: groupWatchingDialogRef,
          animation: 'close',
          stateHandler: () => setShowGroupWatchingDialog(false)
        }),
      1000
    );
  };

  const handleSubmitGroupMembers = async (e) => {
    e.preventDefault();
    const filteredGroupMembers = groupMembers.filter((member) => member.name);
    if (filteredGroupMembers.length) {
      await firebase.userProfile
        .updateGroupWatching({
          uid: user.uid,
          groupWatching: Object.assign(user.groupWatching, {
            [eventId]: filteredGroupMembers
          })
        })
        .then(closeGroupWatchingDialog)
        .catch((err) => {
          console.error(err);
          setError({
            code: err.code,
            message: err.message
          });
        });
    }
  };

  const handleNoGroupMembers = async (e) => {
    e.preventDefault();
    await firebase.userProfile
      .updateGroupWatching({
        uid: user.uid,
        groupWatching: Object.assign(user.groupWatching, {
          [eventId]: []
        })
      })
      .then(closeGroupWatchingDialog)
      .catch((err) => {
        console.error(err);
        setError({
          code: err.code,
          message: err.message
        });
      });
  };

  return (
    <Container colors={colors}>
      {!addGroupMembers && !message ? (
        <>
          <Message colors={colors}>
            <p>{question}</p>
          </Message>
          <Options>
            <Button onClick={() => setAddGroupMembers(true)}>Yes</Button>
            <Button onClick={handleNoGroupMembers}>No</Button>
          </Options>
        </>
      ) : (
        <AnimatePresence>
          {message ? (
            <Message initial={{ opacity: 0 }} animate={{ opacity: 1 }} colors={colors}>
              {message}
            </Message>
          ) : (
            <>
              <h4>If watching with multiple people, please add their names below:</h4>
              <GroupMembers
                groupMembers={groupMembers}
                setGroupMembers={setGroupMembers}
                handleGroupMemberTextChange={handleGroupMemberTextChange}
                colors={colors}
              />
              {error && <p>{error.message}</p>}
              <Options>
                <Button onClick={() => setAddGroupMembers(false)}>Go Back</Button>
                <Button disabled={groupMembers.length === 0} onClick={handleSubmitGroupMembers}>
                  {user?.groupWatching?.[eventId] ? 'Update' : 'Add'}
                </Button>
              </Options>
            </>
          )}
        </AnimatePresence>
      )}
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  position: relative;
  width: 23.75rem;

  h4 {
    color: #4f4f4f;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 1.5rem;
  }

  button {
    border-radius: 2px !important;
  }
`;

const Options = styled.div`
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding: 1rem 0;
`;

const Message = styled.div`
  color: ${({ colors }) => colors.tertiary};
  font-weight: 700;
  padding: 0.75rem 0;
  text-align: center;
  width: 100%;
`;

export default GroupWatchingDialog;
