import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FirebaseContext, LocalContext } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import { PinComment } from 'components/Events/Livestream/Interaction/Chat';
import { useIntersection } from 'hooks';
import { hexToRGB } from 'utils';
import { format } from 'date-fns';
import { Star, Upvote, Bin, Minimise, Maximise } from 'assets/dynamic-svgs';

function SubmittedQuestion({
  eventId,
  submittedQuestion,
  selectedQuestion,
  setSelectedQuestion,
  submittedQuestionsRef,
  questionToBeDeleted,
  setQuestionToBeDeleted,
  editQuestion,
  setEditQuestion,
  setTextArea,
  index,
  userQuestions,
  colors
}) {
  const {
    minimisedQuestions,
    setMinimisedQuestions,
    showConfirmDeleteDialog,
    setShowConfirmDeleteDialog
  } = useContext(LocalContext);
  const { firebase, user } = useContext(FirebaseContext);
  const [upvotedAlready, setUpvotedAlready] = useState(false);

  const [ref, observerEntry] = useIntersection({
    root: submittedQuestionsRef.current,
    threshold: 1
  });

  useEffect(() => {
    if (submittedQuestion.upvotes?.upvoters?.length > 0) {
      if (submittedQuestion.upvotes.upvoters.includes(user.uid)) {
        setUpvotedAlready(true);
      }
    }
  }, [submittedQuestion]);

  useEffect(() => {
    if (
      user?.isModerator?.includes(eventId) &&
      !submittedQuestion.seen.status &&
      observerEntry.isIntersecting
    ) {
      firebase.interaction.qAndA.markQuestionSeen({
        uid: submittedQuestion.uid,
        qid: submittedQuestion.qid
      });
    }
  }, [user?.isModerator, observerEntry.isIntersecting]);

  const handleQuestionSelection = () => {
    if (user?.isModerator?.includes(eventId)) {
      if (selectedQuestion?.qid === submittedQuestion.qid) {
        setSelectedQuestion(null);
      } else {
        setSelectedQuestion(submittedQuestion);
      }
    }
  };

  const handleStarQuestion = ({ e, uid, qid, isStarred }) => {
    e.stopPropagation();
    if (isStarred) {
      firebase.interaction.qAndA.unstarQuestion({ uid, qid });
    } else {
      firebase.interaction.qAndA.starQuestion({ uid, qid });
    }
  };

  const handleMinMaxQuestion = ({ e, qid }) => {
    e.stopPropagation();
    if (minimisedQuestions.includes(qid)) {
      setMinimisedQuestions(minimisedQuestions.filter((question) => question !== qid));
    } else {
      setMinimisedQuestions([...minimisedQuestions, qid]);
    }
  };

  const handleEditToggle = () => {
    if (editQuestion.edit) {
      setEditQuestion({
        edit: false,
        question: {
          uid: '',
          qid: '',
          text: ''
        }
      });
      setTextArea('');
    } else {
      setEditQuestion({
        edit: true,
        question: {
          uid: submittedQuestion.uid,
          qid: submittedQuestion.qid,
          text: submittedQuestion.text
        }
      });
      setTextArea(submittedQuestion.text);
    }
  };

  const variants = {
    visible: {
      height: 'auto',
      opacity: 1,
      transition: {
        height: {
          duration: 0.4
        },
        opacity: {
          duration: 0.25,
          delay: 0.15
        }
      }
    },
    hidden: {
      height: 0,
      opacity: 0,
      transition: {
        height: {
          duration: 0.4
        },
        opacity: {
          duration: 0.25
        }
      }
    }
  };

  return (
    <Wrapper
      layout="position"
      layoutDependency={index}
      ref={ref}
      colors={colors}
      isStarred={submittedQuestion.starred.status}
      isModerator={user?.isModerator?.includes(eventId)}
      isSelected={selectedQuestion?.qid === submittedQuestion.qid}
      isMinimised={minimisedQuestions.includes(submittedQuestion.qid)}
      onClick={handleQuestionSelection}>
      <QuestionContainer>
        {user?.isModerator?.includes(eventId) && (
          <SelectQuestionCheckbox
            checked={selectedQuestion?.qid === submittedQuestion.qid}
            id="selectQuestionCheckbox"
            name="selectQuestionCheckbox"
            colors={colors}
            type="checkbox"
            readOnly
          />
        )}
        <p>
          <span>
            {user?.uid === submittedQuestion.uid
              ? `My Question${
                  userQuestions.length > 1
                    ? ` (${
                        userQuestions.findIndex(
                          (question) => question.qid === submittedQuestion.qid
                        ) + 1
                      })`
                    : ''
                }`
              : submittedQuestion.name
                ? `${submittedQuestion.name}`
                : 'Anonymous User'}
          </span>
          <Actions>
            {user?.isModerator?.includes(eventId) && (
              <StarQuestion
                colors={colors}
                isStarred={submittedQuestion.starred.status}
                onClick={(e) =>
                  handleStarQuestion({
                    e,
                    uid: submittedQuestion.uid,
                    qid: submittedQuestion.qid,
                    isStarred: submittedQuestion.starred.status
                  })
                }>
                <Star />
              </StarQuestion>
            )}
            {user?.isModerator?.includes(eventId) && (
              <MinMaxQuestion
                onClick={(e) => handleMinMaxQuestion({ e, qid: submittedQuestion.qid })}>
                {minimisedQuestions.includes(submittedQuestion.qid) ? <Maximise /> : <Minimise />}
              </MinMaxQuestion>
            )}
          </Actions>
        </p>
        <AnimatePresence>
          {!minimisedQuestions.includes(submittedQuestion.qid) && (
            <>
              <motion.p initial="hidden" animate="visible" exit="hidden" variants={variants}>
                {submittedQuestion.text}
              </motion.p>
              {submittedQuestion.timestamp && (
                <motion.p initial="hidden" animate="visible" exit="hidden" variants={variants}>
                  <span>{format(submittedQuestion.timestamp.toDate(), 'HH:mmaaa')}</span>
                  {user.uid === submittedQuestion.uid && (
                    <EditQuestion
                      colors={colors}
                      whileTap={{ scale: 0.9 }}
                      enabled={editQuestion.edit}
                      onClick={handleEditToggle}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M373.1 24.97C401.2-3.147 446.8-3.147 474.9 24.97L487 37.09C515.1 65.21 515.1 110.8 487 138.9L289.8 336.2C281.1 344.8 270.4 351.1 258.6 354.5L158.6 383.1C150.2 385.5 141.2 383.1 135 376.1C128.9 370.8 126.5 361.8 128.9 353.4L157.5 253.4C160.9 241.6 167.2 230.9 175.8 222.2L373.1 24.97zM440.1 58.91C431.6 49.54 416.4 49.54 407 58.91L377.9 88L424 134.1L453.1 104.1C462.5 95.6 462.5 80.4 453.1 71.03L440.1 58.91zM203.7 266.6L186.9 325.1L245.4 308.3C249.4 307.2 252.9 305.1 255.8 302.2L390.1 168L344 121.9L209.8 256.2C206.9 259.1 204.8 262.6 203.7 266.6zM200 64C213.3 64 224 74.75 224 88C224 101.3 213.3 112 200 112H88C65.91 112 48 129.9 48 152V424C48 446.1 65.91 464 88 464H360C382.1 464 400 446.1 400 424V312C400 298.7 410.7 288 424 288C437.3 288 448 298.7 448 312V424C448 472.6 408.6 512 360 512H88C39.4 512 0 472.6 0 424V152C0 103.4 39.4 64 88 64H200z" />
                      </svg>
                    </EditQuestion>
                  )}
                  {user.uid === submittedQuestion.uid ||
                    (user?.isModerator?.includes(eventId) && (
                      <DeleteQuestion
                        colors={colors}
                        onClick={() => {
                          setQuestionToBeDeleted({
                            uid: submittedQuestion.uid,
                            qid: submittedQuestion.qid
                          });
                          setShowConfirmDeleteDialog(true);
                        }}>
                        <Bin
                          deleteDialogOpen={
                            showConfirmDeleteDialog &&
                            questionToBeDeleted.qid === submittedQuestion.qid
                          }
                        />
                      </DeleteQuestion>
                    ))}
                </motion.p>
              )}
            </>
          )}
        </AnimatePresence>
      </QuestionContainer>
      <AnimatePresence initial={false}>
        {submittedQuestion.answer && !minimisedQuestions.includes(submittedQuestion.qid) && (
          <ModeratorsAnswer
            colors={colors}
            isModerator={user?.isModerator?.includes(eventId)}
            isSelected={selectedQuestion?.qid === submittedQuestion.qid}>
            <p>
              <span>Moderator&apos;s Answer:</span>
            </p>
            <p>{submittedQuestion.answer.text}</p>
            <p>
              {submittedQuestion.answer.timestamp && (
                <span>{format(submittedQuestion.answer.timestamp.toDate(), 'HH:mmaaa')}</span>
              )}
            </p>
          </ModeratorsAnswer>
        )}
      </AnimatePresence>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: {
        duration: 0.4
      },
      opacity: {
        duration: 0.25,
        delay: 0.15
      }
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      height: {
        duration: 0.4
      },
      opacity: {
        duration: 0.25
      }
    }
  }
})`
  background-color: ${({ isSelected, colors }) =>
    isSelected ? hexToRGB({ color: colors.tertiary, alpha: 0.2 }) : 'transparent'};
  color: ${({ isSelected, colors }) => (isSelected ? colors.tertiary : '#000')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  font-size: 1rem;
  margin-bottom: 0.688em;
  padding: 0.25em;
  position: relative;
  transition:
    background-color 200ms ease-in-out,
    color 200ms ease-in-out;
  p {
    padding-left: ${({ isModerator }) => (isModerator ? '1.375rem' : 0)};
  }
  p:first-of-type {
    width: 100%;
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${({ isMinimised }) => (isMinimised ? 0 : '0.313em')};

    span:first-of-type {
      font-weight: 700;
    }
  }

  p:nth-of-type(3) {
    width: auto;
    align-items: flex-end;
    display: flex;
    margin-top: 0.375rem;

    span {
      color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
      transition: color 200ms ease-in-out;
      font-size: 0.75em;
      position: ${({ isModerator }) => (isModerator ? 'relative' : 'absolute')};
      top: ${({ isModerator }) => (isModerator ? 0 : '0.4em')};
      right: 0;
    }
  }
  small {
    color: ${({ isStarred, colors }) => (isStarred ? colors.tertiary : '#000')};
    display: inline-block;
    font-size: 0.65rem;
    left: 0.95em;
    position: relative;
  }
`;

const QuestionContainer = styled.div`
  position: relative;
`;

export const SelectQuestionCheckbox = styled.input`
  appearance: none;
  background-color: transparent;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  height: 1rem;
  margin-top: 2px;
  outline: none;
  position: absolute;
  transition: all 0.1s;
  width: 1rem;
  &:focus {
    border: 2px solid ${({ colors }) => colors.tertiary};
  }
  &:checked {
    border: 1px solid ${({ colors }) => colors.tertiary};
  }
  &:checked:before {
    color: ${({ colors }) => colors.tertiary};
    content: '✓';
    font-size: 0.8rem;
    height: 0.8rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-37%, -60%);
    width: 0.8rem;
  }
`;

const ModeratorsAnswer = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    height: 0
  },
  animate: {
    opacity: 1,
    height: 'auto',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4
      },
      opacity: {
        duration: 0.65,
        delay: 0.1
      }
    }
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      height: {
        duration: 0.4,
        delay: 0.55
      },
      opacity: {
        duration: 0.65
      }
    }
  }
})`
  color: ${({ isSelected }) => (isSelected ? '#000' : '#c4c4c4')};
  cursor: ${({ isModerator }) => (isModerator ? 'pointer' : 'auto')};
  margin-top: 0.625rem;
  padding: 0.125rem 0 0.25rem 1rem;
  transition: color 200ms ease-in-out;
  position: ${({ isModerator }) => (isModerator ? 'unset' : 'relative')};

  p:nth-of-type(3) span {
    top: ${({ isModerator }) => (isModerator ? 0 : '0.6em')};
  }
`;

const Actions = styled.div`
  display: flex;
  flex-flow: nowrap;
  gap: 0.313em;
`;

const DeleteQuestion = styled(PinComment)`
  align-self: center;
  margin-left: auto;
  > svg {
    fill: ${({ colors }) => colors.tertiary};
    height: 1.1em;
    left: unset;
    position: relative;
    width: 1.1em;
  }
`;

const EditQuestion = styled(PinComment)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 0.25rem;
  > svg {
    bottom: unset;
    fill: ${({ colors }) => colors.tertiary};
    height: 1.1em;
    left: unset;
    position: relative;
    width: 1.1em;
  }
`;

const StarQuestion = styled(PinComment)`
  margin-left: 0;
  > svg {
    bottom: unset;
    fill: ${({ isStarred, colors }) => (isStarred ? colors.tertiary : 'transparent')};
    left: unset;
    position: relative;
    stroke: ${({ colors }) => colors.tertiary};
  }
`;

const MinMaxQuestion = styled(PinComment)`
  margin-left: 0;
  > svg {
    bottom: unset;
    left: unset;
    position: relative;
  }
`;

export default SubmittedQuestion;
