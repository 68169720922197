/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Layout } from 'components';
import queryString from 'query-string';
import { LocalContextProvider } from './src/context/LocalContext';

export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

export const wrapRootElement = ({ element }) => (
  <LocalContextProvider>{element}</LocalContextProvider>
);

export const onClientEntry = () => {
  const parseUrl = (params) => {
    const queryParams = queryString.parse(params);
    /* eslint-disable camelcase */

    let { utm_source, utm_medium, li_fat_id } = queryParams;

    if (li_fat_id) {
      li_fat_id = 'Linked In - Social';
    }

    if (utm_source) {
      utm_source = utm_source
        .split('_')
        .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }

    if (utm_medium) {
      utm_medium = utm_medium
        .split('_')
        .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }

    return utm_source || li_fat_id || document.referrer;
  };

  const referringUrl = JSON.parse(window.localStorage.getItem('referringUrlILDWebinar'))?.data;

  if (!referringUrl) {
    window.localStorage.setItem(
      'referringUrlILDWebinar',
      JSON.stringify({
        data: parseUrl(window.location.search) || 'Direct Navigation'
      })
    );
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
};

// Solves a provlem with gatsby-plugin-offline...
// https://stackoverflow.com/questions/58225352/need-to-refresh-previously-vistied-gatsby-site-to-view-changes
// export const onServiceWorkerUpdateReady = () => {
//   const queryParams = queryString.parse(window.location.search);
//   const { mode } = queryParams;
//   if (!mode) {
//     // eslint-disable-next-line no-alert
//     const answer = window.confirm(
//       // eslint-disable-next-line no-useless-concat
//       `This application has been updated. ` + `Reload to display the latest version?`
//     );
//     if (answer === true) {
//       console.log('Service worker update ready. Refreshing site.');
//       window.location.reload();
//     }
//   }
// };
